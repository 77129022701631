import { useQuery } from '@tanstack/react-query'

import { listVailSolutions } from '@/graphql/queries'
import {
  ListVailSolutionsQuery,
  ListVailSolutionsQueryVariables,
  ModelVailSolutionFilterInput,
  VailSolution,
  Visibility,
} from '@/utils/api'

import graphQLRequest from '../../../utils/graphqlClient'

export const useListSolution = (
  orgID?: string,
  enabled = true,
  filter?: ModelVailSolutionFilterInput,
  extraKey?: string[]
) => {
  const queryKey = ['solutions', orgID, ...(extraKey ?? [])]
  return useQuery({
    enabled: enabled && !!orgID,
    queryFn: async () => {
      try {
        let items: VailSolution[] = []
        let finishedGettingHistory = false
        let nextToken: string | undefined | null
        do {
          const req = await graphQLRequest<
            ListVailSolutionsQuery,
            ListVailSolutionsQueryVariables
          >(listVailSolutions, {
            filter: !!filter
              ? filter
              : {
                  and: [
                    { organizationSolutionId: { eq: orgID } },
                    {
                      visibility: {
                        ne: Visibility.archived,
                      },
                    },
                  ],
                },
            ...(!!nextToken ? { nextToken } : {}),
          })
          items = [
            ...items,
            ...(req.data?.listVailSolutions?.items ?? []),
          ] as VailSolution[]
          nextToken = req.data.listVailSolutions?.nextToken
          if (!req.data.listVailSolutions?.nextToken)
            finishedGettingHistory = true
        } while (!finishedGettingHistory)
        return items
        // return (req.data?.listVailSolutions?.items ?? []) as VailSolution[]
      } catch (e) {
        return []
      }
    },

    queryKey: queryKey,
  })
}
