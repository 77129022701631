import { Organization, orgStatus } from '@/utils/api'

export const defaultOrgs: Omit<
  Organization,
  '__typename' | 'updatedAt' | 'createdAt'
>[] = [
  {
    id: '0213ad05-b393-48ab-a48f-20d099525e6c',
    name: 'Precision',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://precisionpharma.com.au/privacy-policy/',
    slug: 'precision',
    tags: ['precision'],
    termsOfUse: 'https://precisionpharma.com.au/terms-and-conditions/',
  },
  {
    id: 'd2943b65-b804-4d31-a2ae-1876c7839de0',
    name: 'Otsuka',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://www.otsuka-us.com/privacy-policy',
    slug: 'otsuka',
    tags: ['otsuka'],
    termsOfUse: 'https://www.otsuka-us.com/terms-and-conditions',
  },
  {
    id: 'a0f53f6d-39b2-4538-86c8-cbb8e3a941f4',
    name: 'PharmaCo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://pharmaco.com.au/privacy-policy/',
    slug: 'pharmaco',
    tags: ['pharmaco'],
    termsOfUse: 'https://pharmaco.com.au/website-terms-of-use/',
  },
  {
    id: 'f88ca22c-5f2e-466e-9f77-eaf75deee1b6',
    name: 'Viiv',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://viivhealthcare.com/en-gb/privacy-notice/',
    slug: 'viiv',
    tags: ['viiv'],
    termsOfUse: 'https://viivhealthcare.com/en-gb/terms-of-use/',
  },
  {
    id: 'ace64373-edb7-43c5-a288-6683d40c060b',
    name: 'Demo Astrazeneca',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy:
      'https://www.azprivacy.astrazeneca.com/americas/unitedstates/en/privacy-notices.html',
    slug: 'demoastrazeneca',
    tags: ['demo', 'demoastrazeneca'],
    termsOfUse:
      'https://www.astrazeneca.com/content/dam/az/PDF/2023/AZ-General-Conditions-of-Contract-US-revJan2023.pdf',
  },
  {
    id: '3bc86b0d-4738-41a1-a8dc-774cf0f8d9fe',
    name: 'Demo GSK',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://privacy.gsk.com/en-gb/privacy-notice/general/',
    slug: 'demogsk',
    tags: ['demo', 'demogsk'],
    termsOfUse: 'https://www.gsk.com/en-gb/terms-of-use/',
  },
  {
    id: 'cdb6b1a7-bbfe-40e5-8f47-c99db31645f4',
    name: 'Demo Biogen',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://www.biogen.com/privacy-center.html',
    slug: 'demobiogen',
    tags: ['demo', 'demobiogen'],
    termsOfUse: '',
  },
  {
    id: '5874ff48-aa37-488d-93d6-07fd3c32cf13',
    name: 'Demo JNJ',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demojnj',
    tags: ['demo', 'demojnj'],
    termsOfUse: '',
  },
  {
    id: '5e8a9dba-5538-4761-a44b-f60fc426296b',
    name: 'Demo Abbvie',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demoabbvie',
    tags: ['demo', 'demoabbvie'],
    termsOfUse: '',
  },
  {
    id: 'b8b923d3-fdd2-4d42-9669-5506fb833a8d',
    name: 'Demo Pfizer',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demopfizer',
    tags: ['demo', 'demopfizer'],
    termsOfUse: '',
  },
  {
    id: '768ea6a8-35f7-4d24-9242-7a9f822021a0',
    name: 'Demo Novartis',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demonovartis',
    tags: ['demo', 'demonovartis'],
    termsOfUse: '',
  },
  {
    id: 'f58fae56-a456-4266-bc3f-83cef5e11d6c',
    name: 'Demo Ipsen',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demoipsen',
    tags: ['demo', 'demoipsen'],
    termsOfUse: '',
  },
  {
    id: '6b07193d-50bd-43a5-b85a-8008a283fe14',
    name: 'Demo Merck',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demomerck',
    tags: ['demo', 'demomerck'],
    termsOfUse: '',
  },
  {
    id: '72e5d17f-3247-4e3e-b9a6-e5adbcf3141a',
    name: 'Demo UBC',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demoubc',
    tags: ['demo', 'demoubc'],
    termsOfUse: '',
  },
  {
    id: 'b2583df1-1244-4696-9c51-8fb5e8a0930c',
    name: 'Demo Daiichi',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: '',
    slug: 'demodaiichi',
    tags: ['demo', 'demodaiichi'],
    termsOfUse: '',
  },
  {
    id: 'd925db3e-d511-4b06-95ec-9a58c485881a',
    name: 'Demo viiv',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://viivhealthcare.com/en-gb/cookie-policy/',
    slug: 'demoviiv',
    tags: ['local', 'generated'],
    termsOfUse: 'https://viivhealthcare.com/en-gb/terms-and-conditions/',
  },
  {
    id: '3fa551c9-68bf-49e8-9ba6-9f5e2c677c99',
    name: 'Brookdale Hospital',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'brookdale',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'ab3ec398-0164-4cd1-9f3b-5e5722d28d68',
    name: 'Demo WFH GTR',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'https://viivhealthcare.com/en-gb/terms-and-conditions/',
    slug: 'demowfhgtr',
    tags: ['local', 'generated'],
    termsOfUse: 'https://viivhealthcare.com/en-gb/terms-of-use/',
  },
  {
    id: '48214961-cb33-48e6-a6a6-d765fb6398ad',
    name: 'Demo Central Hospital',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'democentralhospital',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '4aed1a9a-436f-4d6a-b288-f8442b6af191',
    name: 'Roche',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'roche',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '92359d6c-1785-47ef-957a-09ea36e9104a',
    name: 'MorningSun Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ML43389',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '1f4c66e1-04b7-423b-9028-80c357de653b',
    name: 'CHALLENGE-MIG',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ZZA34831',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'bdfc1a7b-a720-4214-afff-31d84b1576e0',
    name: 'MARS-17',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'mars17',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '02cb10ce-ae94-424e-abbe-ab16215e331f',
    name: 'Health Check',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'healthcheck',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'eedd6027-6a86-4661-9e48-1a2bf6685330',
    name: 'Walmart',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'walmart',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '859bb895-8e46-44f9-ace0-b86fbf75b433',
    name: 'Demo BioMarin RareConnections',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demorareconnections',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'af54f9f6-d708-48ac-8a58-a1485a2790c6',
    name: 'IQVIA',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'iqvia',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '758ed617-0d2c-449b-b933-15b9ebe68d07',
    name: 'ACEP',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'a1916791-432c-425d-a980-37bb994402a4',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'acep',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'a3e1f524-17e0-48fb-a078-e5ddbc6acbc7',
    name: 'ALPHA Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'HAB40220',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '5a3ceac9-912c-4de9-aad1-33af7c3668e9',
    name: 'GSK Boost Meningitis Vaccine Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'QZA97505',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '6458d1a4-5f13-4488-a599-ff7f24015540',
    name: 'Test',
    orgStatus: orgStatus.inactive,
    organizationUpdatedById: 'a1916791-432c-425d-a980-37bb994402a4',
    slug: 'test',
    tags: [],
    termsOfUse: 'http://lifelinksystems.com',
  },
  {
    id: '4fd0467d-c4f7-4dd3-a1bf-1e50249da2e9',
    name: 'ViiV CARISEL Trial Site 8',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite17',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '031d04f8-9ea1-4999-88c9-c2b67c40c0f1',
    name: 'Banner ED Operator',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'banneroperator',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'f567e2bf-5de2-4d0c-9d13-ae50f5f82c5c',
    name: 'SK Life Science Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demosklifescience',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '8e33888a-5826-40ae-af44-dac9a8a9eae7',
    name: 'ViiV CARISEL Trial Site 4',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite13',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '27f4f502-798b-4e86-829f-18790b15934f',
    name: 'GSK Lithe Asthma Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'OZA78186',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e2eb103f-fe24-480b-ae38-4851ba3a9854',
    name: 'AcRIS Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'acrisstudy',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b1409eae-1448-4865-ae8f-b573f1299a67',
    name: 'Care Continuity',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'carecontinuity',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '674d5243-dbfe-4f70-890c-cb1b7370b944',
    name: 'Demo Argenx Prescription Management',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoefgartigimod',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '6df8c9e7-9f78-41f4-92e0-b38ca22c0158',
    name: 'CALM Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'TZA68985',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '98b171d5-f4e3-4707-9b02-12414ec765e3',
    name: 'Test',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'test',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'bbd9db15-17fd-423c-a509-758c95196908',
    name: 'Mannkind Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demomannkind',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'cedcc1eb-28c9-4ff4-81fc-fb2d332be0fc',
    name: 'Demo Genentech Staff Surveys',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demogenentechstaffsurvey',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '157200f0-2b12-4e39-a04a-e0d409c27c76',
    name: 'Baptist Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demobaptist',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '94c1681f-7156-404a-ae30-ce2d2e2ed6b0',
    name: 'SCORPIO-HR',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'HAB23914',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '9201bbf5-1c45-40a1-b6de-dcaf72f00e14',
    name: 'ASCO Cancer.net',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'asco',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'dce4bcb0-df4d-45c5-ad26-a30b9d3f51f5',
    name: 'Peninsula Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'HAB82245',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'dbeeaee9-87d7-470b-ac63-ccb9e24e4589',
    name: 'ViiV CARISEL Trial Site 1',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite10',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '99c8519f-b60e-4757-a764-eeac7bb85260',
    name: 'Banner HRA',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'bannerhra',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '672c6e00-452c-452e-a24b-f0c36f1e8923',
    name: 'Merck UK',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'merck',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '42da5b0f-e2a9-498b-98dc-5c98bff80ba0',
    name: 'GBT Source',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'gbtsource',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'ecc2b61d-8f78-4383-b576-406f277cc5e3',
    name: 'DUOVI Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'HAB85115',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'ff80c9a2-3a71-4b2e-9a36-c220c543db6b',
    name: 'ViiV HIV Prevention Trial Site 2',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite2',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '0b381002-2ca2-4d79-be78-639c428bbc8b',
    name: 'Demo Argenx Prescription Management',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demomyvyvgartpath',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'd00b99e3-690d-4710-9745-3f8720c1d756',
    name: 'AstraZeneca Provent COVID-19 Vaccine Trial',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'SZA63903',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: '5ad76328-03ce-4959-894a-e349e629b5ec',
    name: 'Demo Illinois ER Screener',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demochi',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'db237680-2908-49b7-885f-045f400b5182',
    name: 'AcRIS Study Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoacrisstudy',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e89234fe-8864-4601-a5e5-30d77489a30b',
    name: 'ViiV CARISEL Trial Site 2',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite11',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '740d1b49-a23e-4824-83c1-bff8af5bb1f8',
    name: 'Demo Merck UK',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demomerck',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '5c84e3d1-2ef3-459c-ac29-99b445c41543',
    name: 'Demo The Elevatum Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demotheelevatumstudy',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e009b046-406e-4a81-880f-7315479e17f2',
    name: 'Kaleida Health',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'kaleidahealth',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'a9be1236-8d97-4d5b-a9a1-5f70c317d2ad',
    name: 'ViiV CARISEL Trial Site 11',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite20',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b4f8ca27-89be-49af-881a-815ff706eaf3',
    name: 'DrFirst',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'rxinform',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '6f0d1448-d1c6-4cf1-94e2-dafb9ed57587',
    name: 'Gilead',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'gilead',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '16645b40-5c55-4a95-bfc8-156ec242b1a6',
    name: 'ED Operator Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demooperator',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'abaad380-2188-40ef-95b5-546116cefba9',
    name: 'Genentech Medical Information',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'genentechmi',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '159f50b9-48f7-48e3-a026-9ee9f2404728',
    name: 'Jefferson',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'jefferson',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'f4018516-cc07-40cf-a938-9f011439e132',
    name: 'Central Hospital',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'centralhospital',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e3fd0109-ba2d-4272-8414-73a06e2d1b50',
    name: 'ViiV Presumptive Fill',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'viiv',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: '82e9471f-fc7b-4afb-b08c-8ea57e927bee',
    name: 'Release Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'OZA76392',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '6a5e2797-ca64-46df-a613-17c31d6242c0',
    name: 'PEP Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demopep',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '1304c021-c342-4a16-a4ca-dcd7fc29c5fd',
    name: 'Demo Gilead',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demogilead',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'fc125eaa-5d97-43e7-9aa6-6f9ccd73b80a',
    name: 'EVE Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'WZA97329',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'c3b90c42-b500-4496-8c94-b5a7175cae5d',
    name: 'ViiV CARISEL Trial Site 6',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite15',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '162f61d5-1ece-46e8-a884-edf30221b574',
    name: 'KARDIA Blood Pressue Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'KZA36162',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '9b46b58e-3591-4452-a264-30e9cf8818f9',
    name: 'Demo DrFirst',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demorxinform',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b7571c6e-6ae9-4a65-b299-4b8c784d383f',
    name: 'Baptist Health',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'baptistjax',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '902f46ac-1944-429a-ad69-76d629445306',
    name: 'BioMarin RareConnections',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'rareconnections',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '70eff632-1382-4dd2-826a-2a06e15f644d',
    name: 'Demo Xarelto',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoxarelto',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'a211519b-67d1-452f-a8e7-468291ac6e9a',
    name: 'Claviprin',
    orgStatus: orgStatus.active,
    organizationUpdatedById: '31892a55-a2c0-451d-8111-a3dfc00767eb',
    slug: 'claviprin',
    tags: [
      'brand digital front door',
      'access and support',
      'specialty medication',
    ],
    termsOfUse: 'https://www.claviprin.com/termofuse',
  },
  {
    id: 'e1628545-9a86-4fe5-9138-fe34593bd5fe',
    name: 'Demo Journey Mate Support Program',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demojourneymatesupport',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'bcc491f2-51de-4534-99b5-077d1f022c1c',
    name: 'IQVIA Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoiqvia',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'cf6c3863-f21d-4c75-aa76-09f61c394396',
    name: 'Demo Spravato',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demost858',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '5651eecd-850d-4aff-af60-2c47a82c9d78',
    name: 'Clear-Up AD Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'IAB06241',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '20b849fc-8d4e-4f67-9878-45fa55164df0',
    name: 'Afrezza Assist',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'afrezzaassist',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '34b20300-5b7b-47f1-a970-6a830e92d188',
    name: 'ViiV HIV Prevention Trial Site 3',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite3',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '684bda96-bee6-4f5c-b84e-0bf041b4cfb6',
    name: 'Michigan Medicine',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'michiganmedicine',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: 'ac652035-e847-4582-b999-86a81a2db33e',
    name: 'SK Life Science',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'sklifescience',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '6cee5894-a5f9-42ac-92a3-4ed509ce227e',
    name: 'Lullaby Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ZZA47662',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '0b5b630a-483d-4ceb-a72e-52616f9e2181',
    name: 'AstraZeneca Storm Chaser COVID-19 Vaccine Trial',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ZZA09445',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '2e3296b0-e35e-4b64-b7fe-25ac15ae7cbc',
    name: 'ViiV CARISEL Trial Site 5',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite14',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '7155b402-0261-4e25-b6d2-f87d02d9c2a2',
    name: 'Lifelink Demos',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'pmdemo',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e7ff9a40-5eeb-48a7-813f-570b2a8fb1c1',
    name: 'ViiV CARISEL Trial Site 10',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite19',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: 'bcda1d49-5a4b-4a4e-878d-7ce621219ce0',
    name: 'ViiV HIV Prevention Trial Site 1',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite1',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b07d6cbf-23a3-4819-ae1a-f1576a881fe1',
    name: 'Genentech',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'genentech',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'fba28428-d5cb-462f-b622-918617f6fcdb',
    name: 'Tennessee ER Screener',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'nash',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '43a1ea86-d800-4526-9a79-0d5e7f1f022a',
    name: 'Memorial Health Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demomemorial',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'fc59746d-3fe5-426a-a95e-2abbd4146e7d',
    name: 'Memorial Health System',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'choosememorial',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'fd2233f2-bff0-410e-979f-3da4285a69c0',
    name: 'ViiV CARISEL Trial Site 3',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite12',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b24dba99-2bf0-4c86-acc1-95ad3ba671cc',
    name: 'ViiV HIV Prevention Trial Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demovsite1',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '56762e05-34e7-4d2f-be9b-3d66fbd2530b',
    name: 'Enliven Health',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'enlivenhealth',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b00f7f3e-ba85-4282-927b-60d9729c8136',
    name: 'Illinois ER Screener',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'chi',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'd994629b-75f9-410b-8c18-54f65bcff92b',
    name: 'Banner Health',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'bannerhealth',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '0ac06c15-95fb-4f34-9651-62ac5721095b',
    name: 'Jannsen',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'janssen',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '711c9dde-4330-45e2-8a9c-425675679226',
    name: 'Westpath',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'westpath',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '3142786d-ccb6-4ed9-a248-feb2480cabef',
    name: 'Palforzia Pathway',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'palforziapathway',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: '17d32d31-c77e-48c0-999d-c9b558d597b4',
    name: 'ED Activate Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoactivate',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '06a11c08-5773-4256-a6da-af674d115c76',
    name: 'Demo Enliven Health',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demoenlivenhealth',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'd5cd7983-52d5-4e2d-aa9e-adcee398f07d',
    name: 'Janssen COVID-19 Vaccine Trial',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'RZA47092',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '746f2e68-fc53-436a-9c65-08ee85b98361',
    name: 'Journey Mate Support Program',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'journeymatesupport',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'c79cfd55-2140-475b-b27c-72760aa89bc5',
    name: 'Janssen Patient Support',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'st857',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '27f5d714-e58e-4822-89aa-93348029cacc',
    name: 'Demo MARS-17',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demomars17',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: 'f2330350-6e8a-4796-85d4-32dcfc932bd6',
    name: 'Demo Palforzia Pathway',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demopalforziapathway',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'e707df6b-ebad-4b5b-ad7d-faebc528cf51',
    name: 'Demo Tennessee ER Screener',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demonash',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '696852bb-6c04-48d9-adf3-a3321c55fd37',
    name: 'WFH GTR',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'wfhgtr',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '4e64c249-5b06-4484-a0c2-5aa70fb6f2b1',
    name: 'BFOR Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'bforstudy',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '77241ac3-0a4d-43ec-8c99-2f5e87557d9c',
    name: 'INCB 54707-206 Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ZZA53154',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '82a80411-dd08-404a-b2c0-4b1e4e0e742c',
    name: 'Lifelink',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'lifelink',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '29ad9c6d-65f2-4ce6-9827-5017d0aeddf7',
    name: 'Stanford Healthcare',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'stanfordhealthcare',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '4d9ef75b-7f69-4429-9a2c-898bf1d6fd88',
    name: 'Spravato',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'st858',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },

  {
    id: '98ab7505-ddc5-43a3-b758-160de2c9cdc1',
    name: 'The Elevatum Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'theelevatumstudy',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'dafd6863-0546-4554-93e8-a59b6e039cce',
    name: 'Watsonville Hospital',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'watsonvillehospital',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'd4a2b5b4-6f73-4e4a-904a-57858c709940',
    name: 'Argenx Prescription Management',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'myvyvgartpath',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '49b4200c-3e01-4a0f-ae80-2a3a3163d509',
    name: 'AstraZeneca COVID-19 Vaccine Trial',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'UZA81741',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '7bfd6a5d-9eb9-42a1-879a-9cc68f137925',
    name: 'Demo GBT Source',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demogbtsource',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '4704b646-ce0f-4dc6-be39-80ee276a42a1',
    name: 'HRA Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demohra',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'ef89dee2-245d-43ea-8c8f-f60ea1e3ce90',
    name: 'Demo Care Continuity',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'democarecontinuity',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '8e3ef61c-6d1e-4860-9188-9e88ad956f68',
    name: 'Genentech Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demogenentech',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '5eee8195-b87b-4fb4-aead-c582b0bf5905',
    name: 'ViiV CARISEL Trial Site 9',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite18',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'faa4ebfd-3f15-44a4-ad1b-2f940c56e21e',
    name: 'Rothman Institute',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'rothmaninstitute',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '728d2c36-63b5-4532-9532-523c433d6974',
    name: 'AbiVax Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'FZA86006',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'bb44d15c-364e-4853-a436-2aa1bfafc10c',
    name: 'Lifelink Systems Onboarding',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'onboarding',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '57edd590-5004-477d-bd1e-eab594997825',
    name: 'UBC',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ubc',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '29789b74-56a0-40b6-9840-2be7783dd886',
    name: 'Novartis Empathy Study',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'ZZA35219',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '62ec8e56-d2af-453f-9301-125e46f3fe55',
    name: 'ViiV CARISEL Trial Site 7',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'vsite16',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'b8cbe379-98b9-4bc9-8dfa-b34c1de09714',
    name: 'Genentech Staff Surveys',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'genentechstaffsurvey',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: '057c0c8d-24c2-4067-b68d-ef213c876862',
    name: 'ED Integrated Demo',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'demointegrated',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
  {
    id: 'edf3fa07-5894-4fec-bf48-15aa712f22f8',
    name: 'Janssen',
    orgStatus: orgStatus.active,
    organizationUpdatedById: 'c98499b5-e596-42b1-b951-4561904a0d36',
    privacyPolicy: 'http://localhost:20002/graphql',
    slug: 'llj',
    tags: ['local', 'generated'],
    termsOfUse: 'http://192.168.1.5:20002/graphql',
  },
]
