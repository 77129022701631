import { useQuery } from '@tanstack/react-query'

import { isAdmin } from '@/utils/isAdmin'

export const useIsAdmin = () =>
  useQuery({
    queryFn: async () => await isAdmin(),
    queryKey: ['isAdmin'],

    //10 minutes in milliseconds
    staleTime: 600000,
  })
