export const ADMIN_GROUP = 'Admins'
export const API_NAME = 'AdminQueries'
export const noop = () => {}

export enum WORKSHOP_ENV {
  QA = 'QA',
  DEV = 'DEV',
  Staging = 'Staging',
}

export type WorkshopEnvType = keyof typeof WORKSHOP_ENV

export const isBrowser = typeof window !== 'undefined'

export const isNavigator = typeof navigator !== 'undefined'

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest

  describe('The constant variables', () => {
    it('should not change', () => {
      expect(WORKSHOP_ENV).toMatchInlineSnapshot(`
        {
          "DEV": "DEV",
          "QA": "QA",
          "Staging": "Staging",
        }
      `)
      expect(API_NAME).toMatchInlineSnapshot('"AdminQueries"')
      expect(ADMIN_GROUP).toMatchInlineSnapshot('"Admins"')
    })
  })
}
