const isNullish = (value: any) => value === null || value === undefined

export default isNullish

if (import.meta.vitest) {
  const { expect, describe, test } = import.meta.vitest
  describe('The isNullishFunction', function () {
    test.each([
      [null, true],
      [undefined, true],
      ['', false],
      [0, false],
      ['ABC', false],
      [1, false],
      [[1, 2, 3], false],
      [{ a: 1 }, false],
      [{}, false],
    ])('isNullish(%i) -> %o', (testValue, expected) => {
      expect(isNullish(testValue)).toBe(expected)
    })
  })
}
