export const stringRule = {
  minLength: {
    message: 'The minimum length is 3 characters',
    value: 3,
  },
}

export const required = {
  required: {
    message: 'This field is required',
    value: true,
  },
}

function isValidHttpUrl(str: string) {
  let url: URL
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const validUrlRule = (value: string) =>
  isValidHttpUrl(value) ? true : 'Invalid URL'

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest

  describe('validate rules', () => {
    it('required', () => {
      expect(required.required).toMatchInlineSnapshot(`
        {
          "message": "This field is required",
          "value": true,
        }
      `)
    })
    it('stringRule', () => {
      expect(stringRule.minLength).toMatchInlineSnapshot(`
        {
          "message": "The minimum length is 3 characters",
          "value": 3,
        }
      `)
    })
    it.each([
      { expected: true, input: 'https://developer.mozilla.org' },
      { expected: true, input: 'http://developer.mozilla.org' },
      { expected: 'Invalid URL', input: 'ssh://developer.mozilla.org' },
      { expected: 'Invalid URL', input: '/🍣🍺' },
    ])('validUrlRule($input) -> $expected', ({ expected, input }) => {
      expect(validUrlRule(input)).toBe(expected)
    })
  })

  it.each([
    { expected: true, input: 'https://developer.mozilla.org' },
    { expected: true, input: 'http://developer.mozilla.org' },
    { expected: false, input: 'ssh://developer.mozilla.org' },
    { expected: false, input: '/🍣🍺' },
  ])('isValidHttpUrl($input) -> $expected', ({ expected, input }) => {
    expect(isValidHttpUrl(input)).toBe(expected)
  })
}
