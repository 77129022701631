import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'

const useScreen = (size: Breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(size))
}

export const useXSmallScreen = () => useScreen('xs')
export const useSmallScreen = () => useScreen('sm')
export const useMediumScreen = () => useScreen('md')
export const useLargeScreen = () => useScreen('lg')
export const useXLargeScreen = () => useScreen('xl')
