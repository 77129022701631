import { assocPath, join, mergeDeepRight, pipe, prop } from 'ramda'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useOrg } from '@/hooks'
import {
  useCreateCustomComponent,
  useGetCustomComponent,
  useUpdateCustomComponent,
} from '@/screens/ComponentLibrary/hooks'
import { camelize, splitAndTrim } from '@/utils'
import { ComponentType } from '@/utils/api'

import { BubbleOptions, SemanticSearchSettings } from '../components'

export const defaultSemanticSearch = {
  largeLanguageModelSettings: {
    adverseEventCheck: false,
    adverseEventDetectedStep: '',
    customOpenAiAdverseEventTurboPrompt: false,
    defaultQuestionKeys: [],
    defaultQuestionText:
      'You can choose one of the questions below or search for something else.',
    haveGptChooseBestQuestion: false,
    includeDefaultQuestions: false,
    initialSearchText: 'What would you like to search first?',
    keywordQuestionNotFoundText: strToBubbleOptions(
      "I'm sorry, I don't have any questions related to your search.\nWould you like to search for something else?"
    ),
    keywordSimilarQuestionsFoundText: strToBubbleOptions(
      'Here are some questions I have related to your search.\n---\nWhich question would you like to view?\n---\nWould you like to view one of these related questions?\n---\nHere are some related question I can answer for you:'
    ),
    keywordSimilarQuestionsQuantity: 3,
    openAiAdverseEventTurboPrompt: [
      {
        content:
          "You are a helpful medical assistant monitoring patient conversations for adverse medical events that could happen after taking a medication. Your job is to determine whether a patient input might indicate that the person writing it is experiencing an adverse health event or that they intent to report an adverse medical event. For example: 'Im having a heart attack.', 'I have a headache' or 'can keep taking this drug if I have a rash?' indicate the patient is experiencing an adverse health event, while something like 'I cut my finger' does not indicate an adverse health event because it cannot be attributed to a medication.",
        role: 'user',
      },
      {
        content:
          "I will give you a 'PATIENT INPUT' and you'll have to decide if they are asking for information about a medication, asking about something else, if they are EXPERIENCING and adverse health event or if they want to report an adverse health event, to do this ask yourself this questions: 1) Is the patient having symptoms? if so, are they attributable to taking a medication?, 2) Is the patient simply asking for information about possible side effects or are they experiencing them?, 3) Is the patient worried about the effects a drug they are taking is ALREADY having on their health?, 4) Is the user input a cause for medical concern? and if it is could it be attributable to a new medication they are taking?, 5) how confident are you that the patient is experiencing an adverse health event? - after making a decision based on this questions take time to think about your final answer, is the patient experiencing an adverse event?; you must answer ONLY with a floating point number from 1 to 4 where 1 means the patient is definitely not experiencing or wanting to report an adverse event and 4 means the patient is definitely experiencing or wanting to report an adverse event, using this options as a guide: 1 - It IS NOT an adverse event, 2 - most likely IT IS NOT an adverse event, 3 - most likely IT IS an adverse event, 4 - It IS an adverse event; answer ONLY with a number, do not answer with anything else.; PATIENT INPUT: 'I'm feeling unusually sweaty lately'.",
        role: 'user',
      },
      { content: '3.2', role: 'assistant' },
      { content: 'I have a headache', role: 'user' },
      { content: '3.9', role: 'assistant' },
      {
        content: 'what are the risks, symptoms or side effects of this drug?',
        role: 'user',
      },
      { content: '1.7', role: 'assistant' },
      { content: 'Can I take this drug with paracetamol?', role: 'user' },
      { content: '1.4', role: 'assistant' },
      { content: 'report adverse event', role: 'user' },
      { content: '4', role: 'assistant' },
    ],
    openAiCompletionPrompt: '',
    pickFromAnswerEmbeddings: true,
    pineconeNamespace: '',
    pineconeTopK: 12,
    questionNotFoundButtons: [
      {
        action:
          'components/semanticSearch/version1_0_1/semanticSearchHandler/askAnotherQuestion',
        disabled: true,
        label: 'Ask another question',
      },
      {
        action:
          'workshop/<org>/<solutionName>/conversationHandler/<componentName>OffRamp',
        disabled: true,
        label: "I'm done for now",
      },
    ],
    questionNotFoundText: strToBubbleOptions(
      "I'm sorry, I was not able to find any answers related to your question.\nWould you like to search for something else?"
    ),
    relatedQuestionsQuantity: 2,
    returningSearchText: strToBubbleOptions(
      'What would you like to search?\n---\nWhat else would you like to ask?'
    ),

    searchInstructions: strToBubbleOptions(
      'You can type a question below or enter a search term like "side effects" to see suggested questions related to that topic.'
    ),
    semanticSearchOutroStep: '',
    semanticSearchTriggerQuestionStep: '',
    showSources: false,
    similarQuestionsFoundButtons: [
      {
        action:
          'components/semanticSearch/version1_0_1/semanticSearchHandler/askAnotherQuestion',
        disabled: true,
        label: 'Ask a new question',
      },
      {
        action: `workshop/<org>/<solutionName>/conversationHandler/<componentName>OffRamp`,
        disabled: true,
        label: "I'm done for now",
      },
    ],
    similarQuestionsFoundText: strToBubbleOptions(
      "I'm sorry, I don't have the answer to your exact question in the database.\nWould you like me to answer one of these similar questions?\n---\nThat question is pretty close to several of the approved questions I have in my database.\nWould you like to view one of these?\n---\nI don't have a question that matches yours exactly, but here are a few that may be close:\n---\nI don't have that exact question but maybe one of these is close?"
    ),
    similarQuestionsQuantity: 2,
    submitQuestionButtonText: 'Submit my question',

    submitQuestionCustomerEmail: 'test-email@lifelinksystems.com',
    submitQuestionEnabled: false,
    submitQuestionExtraBody:
      '{{semanticSearchVariables.submitQuestionExtraBody}}User question: {{userQueryInput}}<br>User email: {{userResponses.demographic.email}}<br>{{tmp.userFullNameText}}',
    submitQuestionText:
      "If you'd like, I can collect your question and send it directly to our team.",
    thresholdToAddQuestionToSuggestions: 0.8,
    thresholdToGoStraightToAnswer: 0.91,
  },
}

const mergeDefaults = mergeDeepRight(defaultSemanticSearch)

export type SemanticSearchSettingsForm = {
  settings: SemanticSearchSettings
  name: string
  description: string
}

export function strToBubbleOptions(str: string | BubbleOptions): BubbleOptions {
  if (typeof str !== 'string') {
    // @ts-ignore
    if (Array.isArray(str) && str[0]?.nestedArray)
      return (
        str
          // @ts-ignore
          .map(({ nestedArray }) => nestedArray.map(prop('value')))
          .map(join('\n'))
          .map((str) => ({ value: str }))
      )

    return str
  }
  const splitMessage = splitAndTrim('---')
  return splitMessage(str).map((value) => ({ value }))
}

export const useSemanticSearchTab = (orgID?: string, componentID?: string) => {
  const org = useOrg(orgID)
  const current = useGetCustomComponent<SemanticSearchSettings>(componentID)
  const create = useCreateCustomComponent<SemanticSearchSettings>(orgID)
  const update = useUpdateCustomComponent<SemanticSearchSettings>(componentID)

  const form = useForm<SemanticSearchSettingsForm>({
    defaultValues: {
      description: '',
      name: '',
      settings: defaultSemanticSearch,
    },
  })
  const buttonsFieldArray = useFieldArray({
    control: form.control,
    name: 'settings.largeLanguageModelSettings.questionNotFoundButtons',
  })
  const openAiAdverseEventTurboPrompt = useFieldArray({
    control: form.control,
    name: 'settings.largeLanguageModelSettings.openAiAdverseEventTurboPrompt',
  })
  const buttonsQuestionsArray = useFieldArray({
    control: form.control,
    name: 'settings.largeLanguageModelSettings.similarQuestionsFoundButtons',
  })
  const { reset } = form

  const navigate = useNavigate()

  useEffect(() => {
    if (!current.data) return

    const { name, description, settings } = current.data
    const mergedDefaults = mergeDefaults(settings)
    const llm = mergedDefaults.largeLanguageModelSettings

    reset(
      {
        description,
        name,
        settings: {
          ...mergedDefaults,
          largeLanguageModelSettings: {
            ...llm,
            keywordQuestionNotFoundText: strToBubbleOptions(
              llm?.keywordQuestionNotFoundText || ''
            ),
            keywordSimilarQuestionsFoundText: strToBubbleOptions(
              llm?.keywordSimilarQuestionsFoundText || ''
            ),

            questionNotFoundText: strToBubbleOptions(
              llm?.questionNotFoundText || ''
            ),
            returningSearchText: strToBubbleOptions(
              llm?.returningSearchText || ''
            ),
            searchInstructions: strToBubbleOptions(
              llm?.searchInstructions || ''
            ),
            similarQuestionsFoundText: strToBubbleOptions(
              llm?.similarQuestionsFoundText || ''
            ),
          },
        },
      },
      {
        keepIsValid: false,
      }
    )
  }, [current.data, org.data?.slug, reset])

  const onSubmit = form.handleSubmit(async (data) => {
    const path = `workshop/${org.data?.slug}/${camelize(
      data.name
    )}/conversationHandler/${camelize(data.name)}OffRamp`

    const dataToSave = pipe<
      [SemanticSearchSettingsForm],
      SemanticSearchSettingsForm,
      SemanticSearchSettingsForm
    >(
      assocPath(
        ['settings', 'largeLanguageModelSettings', 'semanticSearchOutroStep'],
        path
      ),
      assocPath(
        ['settings', 'largeLanguageModelSettings', 'pineconeNamespace'],
        `${camelize(data.name)}_${camelize(org.data?.slug)}`
      )
    )

    if (componentID)
      return await update.mutateAsync({
        ...dataToSave(data),
        id: componentID,
        type: ComponentType.semanticSearch,
      })

    const res = await create.mutateAsync({
      ...dataToSave(data),
      type: ComponentType.semanticSearch,
    })
    return navigate(`../${res?.id}`)
  })

  return {
    buttonsFieldArray,
    buttonsQuestionsArray,
    current,
    form,
    onSubmit,
    openAiAdverseEventTurboPrompt,
  }
}
