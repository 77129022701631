import {
  AccountCircle,
  Add,
  AddToDrive,
  AdminPanelSettings,
  Archive,
  Assignment,
  AutoGraph,
  Cached,
  Check,
  Code,
  Construction,
  CorporateFare,
  Dashboard,
  Delete,
  DeveloperMode,
  Download,
  Edit,
  Error,
  ExpandMore,
  FormatAlignLeft,
  HighlightOff,
  InfoOutlined,
  IntegrationInstructions,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  LightbulbOutlined,
  Link,
  Menu,
  MoreVert,
  NewReleases,
  People,
  QuestionAnswerOutlined,
  Receipt,
  Remove,
  Save,
  Search,
  Security,
  ShoppingCart,
  SportsScore,
  SvgIconComponent,
  Tune,
  Visibility,
  Window,
} from '@mui/icons-material'
import { useMemo } from 'react'

import { MuiIconNames } from './names'

export interface IconProps extends Omit<SvgIconComponent, 'muiName'> {
  name: MuiIconNames
}
export const Icon = ({ name, ...rest }: IconProps) => {
  const CustomIcon = useMemo(() => {
    switch (name) {
      case 'task-tracker':
        return Assignment
      case 'archived':
        return Archive
      case 'view':
        return Window
      case 'error':
        return Error
      case 'graph':
      case 'eg':
        return AutoGraph
      case 'download':
        return Download
      case 'check':
        return Check
      case 'account-circle':
        return AccountCircle
      case 'edit':
        return Edit
      case 'look':
      case 'search':
        return Search
      case 'cached':
      case 'loading':
        return Cached
      case 'link':
        return Link
      case 'document':
        return Receipt
      case 'arrow-up':
      case 'chevron-up':
        return KeyboardArrowUp
      case 'arrow-right':
      case 'chevron-right':
        return KeyboardArrowRight
      case 'arrow-left':
      case 'chevron-left':
        return KeyboardArrowLeft
      case 'chevron-down':
      case 'arrow-down':
        return KeyboardArrowDown
      case 'settings':
        return Tune
      case 'admin':
        return AdminPanelSettings
      case 'construction':
      case 'templates':
        return Construction
      case 'organization':
      case 'corporate':
        return CorporateFare
      case 'minus':
      case 'remove':
        return Remove
      case 'expand-more':
        return ExpandMore
      case 'visibility':
        return Visibility
      case 'align-left':
        return FormatAlignLeft
      case 'add':
        return Add
      case 'add-to-drive':
        return AddToDrive
      case 'code':
        return Code
      case 'dashboard':
        return Dashboard
      case 'developer-mode':
        return DeveloperMode
      case 'info-outlined':
        return InfoOutlined
      case 'integration-instructions':
        return IntegrationInstructions
      case 'new-releases':
        return NewReleases
      case 'people':
        return People
      case 'shopping-cart':
        return ShoppingCart
      case 'save':
        return Save
      case 'sports-score':
        return SportsScore
      case 'star-outlined':
        return QuestionAnswerOutlined
      case 'ai':
        return LightbulbOutlined
      case 'trash':
      case 'delete':
        return Delete
      case 'close':
        return HighlightOff
      case 'menu':
        return MoreVert
      case 'security':
        return Security
      case 'hamburger':
        return Menu
      default:
        console.warn(`The icon ${name} does not exist.`)
        return Add
    }
  }, [name])
  if (!name) {
    console.warn(`Please add the name of the icon`)
    return null
  }
  return <CustomIcon className={`icon-${name}`} {...rest} />
}
