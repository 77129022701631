import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import withSuspense from '@/hocs/withSuspense'

import EngagementGoals from '../EngagementGoals'

const ListSolutions = withSuspense(lazy(() => import('./ListSolutions')))
const EditSolution = withSuspense(lazy(() => import('./EditSolution')))

export default function Solutions() {
  return (
    <Routes>
      <Route element={<ListSolutions />} path="/" />
      <Route element={<EditSolution />} path="/new/:solutionType" />
      <Route element={<EditSolution />} path="/:solutionID" />
      <Route
        element={<EngagementGoals />}
        path="/:solutionID/engagement-goals/*"
      />
      {/*<Route element={<Navigate replace to="/error/404" />} path="*" />*/}
    </Routes>
  )
}
