import { Alert, Box, Typography } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'
import { and, uniq } from 'ramda'
import { Else, If, Then, Unless, When } from 'react-if'

import { JsonDataViewer } from '@/components'
import useValidateEndpoint from '@/hooks/useValidate'
import { Organization, VailEngagementGoal, VailSolution } from '@/utils/api'

import { CustomComponentEnhanced } from '../hooks'

export type ConfigDataScreenProps<T extends object> = {
  org: UseQueryResult<Organization>
  current:
    | UseQueryResult<CustomComponentEnhanced<T> | null>
    | UseQueryResult<VailSolution | null, Error>
    | UseQueryResult<VailEngagementGoal | null, Error>
  valid: ReturnType<typeof useValidateEndpoint>
  config: object
  rootName: string
}

export const ConfigDataScreen = <T extends object>({
  config,
  current,
  org,
  valid,
  rootName,
}: ConfigDataScreenProps<T>) => {
  if (!org.data || !current.data)
    return (
      <Box flex={1}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    )

  const isBothEnvValid = valid.data?.reduce(
    (acc, curr) => and(acc, !!curr?.valid),
    true
  )
  return (
    <Box flex={1}>
      <When condition={valid.isLoading}>
        <Alert severity="info">Validating...</Alert>
      </When>
      <Unless condition={valid.isLoading}>
        <If condition={!!isBothEnvValid}>
          <Then>
            <Alert severity="success">
              Config is valid in {valid.data?.map(({ env }) => env).join(' & ')}
            </Alert>
          </Then>
          <Else>
            <Alert severity="error">
              Config is invalid in{' '}
              {valid.data
                ?.filter(({ valid }) => !valid)
                ?.map(({ env }) => env)
                .join(' & ')}
              <br />
              <Typography>
                {uniq(
                  valid.data
                    ?.filter(({ valid }) => !valid)
                    .map(({ error }) => error) || []
                ).join('\n')}
              </Typography>
            </Alert>
          </Else>
        </If>
      </Unless>
      <JsonDataViewer data={config} rootName={rootName} />
    </Box>
  )
}
