import './index.css'
import '@/utils/amplify'

import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { CustomTheme } from '@/components'
import queryClient from '@/utils/queryClient'

import App from './App'

dayjs.extend(relativeTime)
Sentry.init({
  dsn: 'https://c7a4519cdfc646fe948aeca210d9b52b@o4505007781183488.ingest.sentry.io/4505007827779584',
  enabled: import.meta.env.PROD,
  environment: window.location.hostname.split?.('/')?.[0],
})

const container = document.getElementById('root')
if (!container) throw new Error('No root element found')
const root = createRoot(container)

const RootElement = (
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <CustomTheme>
          <CssBaseline />
          <App />
        </CustomTheme>
      </BrowserRouter>
      <ReactQueryDevtools position="bottom" />
    </QueryClientProvider>
  </StrictMode>
)

root.render(RootElement)
