import { create } from 'zustand'

export type ImageState = {
  keys: Record<string, string | null>
  setKey: (key?: string, value?: string | null) => void
}

export const useImage = create<ImageState>((set) => ({
  keys: {},
  setKey: (key, value) => {
    if (value === undefined || !key) return
    return set((s) => {
      if (s.keys[key] === value) return { keys: { ...s.keys, [key]: null } }

      return { keys: { ...s.keys, [key]: value } }
    })
  },
}))
