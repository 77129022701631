import { TextField, TextFieldProps } from '@mui/material'
import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'

import { PartialBy, safeCypressID } from '@/utils'

import { getError, minLength, required } from './utils'

export type VailTextFieldProps<T extends object> = PartialBy<
  UseControllerProps<T>,
  'control'
> &
  TextFieldProps

/**
 * This is a wrapper around the TextField component from @mui/material & react-hook-form.
 *
 * It is used to make the TextField component work with react-hook-form.
 * @param props
 */
export const VailTextField = <T extends object>(
  props: VailTextFieldProps<T>
) => {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    disabled,
    control,
    ...textFieldProps
  } = props
  const form = useFormContext<T>()

  if (!form && !control)
    throw new Error(
      'VailTextField must be used inside a FormProvider or pass the control prop'
    )

  const controller = useController({
    control: control || form.control,
    defaultValue,
    disabled,
    name,
    rules: rules || { minLength: minLength(3), required: required },
    shouldUnregister,
  })

  const errorName = getError(form.formState, name)
  const { field } = controller

  return (
    <TextField
      {...textFieldProps}
      disabled={disabled}
      error={!!errorName}
      fullWidth={props.fullWidth ?? true}
      helperText={errorName ?? props.helperText}
      id={safeCypressID(props.id ?? props.name)}
      inputRef={field.ref}
      label={props.label}
      type={props.type ?? 'text'}
      value={field.value ?? defaultValue ?? ''}
      onBlur={field.onBlur}
      onChange={(e) => {
        props?.onChange?.(e)
        field.onChange(e)
      }}
    />
  )
}
