import { useQuery } from '@tanstack/react-query'
import { equals, mergeRight, prop, uniqBy } from 'ramda'

import { getCustomComponent } from '@/graphql/queries'
import { useAppsyncRefetch } from '@/hooks'
import { addSmallestNumber, graphQLRequest } from '@/utils'
import {
  CustomComponent,
  GetCustomComponentQuery,
  GetCustomComponentQueryVariables,
  ProcessingStatus,
} from '@/utils/api'

import { SemanticSearchSettings } from '../CustomComponents/SemanticSearch/components'
import { storeKey } from './CONSTANTS'
import { useUpdateCustomComponent } from './useUpdateCustomComponent'

export interface CustomComponentEnhanced<T extends {}>
  extends Omit<CustomComponent, 'settings'> {
  settings: T
}

export async function fetchCustomComponent(id?: string) {
  if (!id) return null
  const req = await graphQLRequest<
    GetCustomComponentQuery,
    GetCustomComponentQueryVariables
  >(getCustomComponent, { id })
  return req
}

export const useGetCustomComponent = <T extends {}>(id?: string) => {
  const isRefetching = useAppsyncRefetch((state) => state.refetchStatus(id))
  const setRefetchStatus = useAppsyncRefetch((state) => state.setRefetch)
  const updateComponent = useUpdateCustomComponent(id)
  return useQuery({
    enabled: !!id,

    queryFn: async () => {
      try {
        const res = await fetchCustomComponent(id)

        if (res === null || res.data?.getCustomComponent === null)
          throw new Error('CustomComponent not found')
        const settings = JSON.parse(
          res.data?.getCustomComponent?.settings || '{}'
        )
        const isProcessing =
          res.data.getCustomComponent?.status === ProcessingStatus.processing
        if (
          res.data?.getCustomComponent?.updatedAt &&
          res.data.getCustomComponent.status === ProcessingStatus.processing
        ) {
          const timeDifference = Math.abs(
            new Date(res.data?.getCustomComponent?.updatedAt).getTime() -
              new Date().getTime()
          )

          // 15 minutes after the last update means that the lamda has hanged
          if (timeDifference > 900000) {
            res.data.getCustomComponent.status = ProcessingStatus.error
            res.data.getCustomComponent.displayStatus =
              'Error: Gen answers took too long. Please try again later.'
          }
        }
        setRefetchStatus(id, isProcessing)
        try {
          const settings = JSON.parse(
            res.data.getCustomComponent?.settings || '{}'
          ) as unknown as SemanticSearchSettings
          const keys = settings?.questions?.map((q) => q.key)

          keys?.forEach((key) => {
            if (isProcessing) return
            setRefetchStatus(`${id}_${key}`, false)
          })
          if (
            settings?.questions?.find(
              (q) => !q.key || !Array.isArray(q.suggestedQuestions)
            )
          ) {
            const defaultQuestionData = mergeRight({
              suggestedQuestions: [],
            })
            const newSettings = {
              ...settings,
              questions: uniqBy(
                prop('key'),
                settings.questions.map((q, idx) =>
                  defaultQuestionData({
                    ...q,
                    key: q.key || addSmallestNumber(keys)?.[idx],
                  })
                )
              ),
            }

            if (!equals(newSettings, settings))
              await updateComponent.mutateAsync({ id, settings: newSettings })

            return {
              ...res.data?.getCustomComponent,
              settings: newSettings,
            } as unknown as CustomComponentEnhanced<T>
          }
        } catch (e) {
          console.log('unsupported component type', e)
        }

        return {
          ...res.data?.getCustomComponent,
          settings,
        } as CustomComponentEnhanced<T>
      } catch (e) {
        return null
      }
    },

    queryKey: [storeKey, id],
    refetchInterval: isRefetching ? 500 : false,
  })
}
