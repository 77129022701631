import { useMutation } from '@tanstack/react-query'

import { updateDocument } from '@/graphql/mutations'
import { useMySnackbar, useMyUser } from '@/hooks'
import { getDefaultMutations, graphQLRequest } from '@/utils'
import {
  UpdateDocumentInput,
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from '@/utils/api'

export const useUpdateDocument = (docID?: string) => {
  const me = useMyUser()
  const snackbars = useMySnackbar({
    errorMessage: 'Failed to update document',
    infoMessage: 'Updating document...',
    successMessage: 'Document updated successfully',
  })
  return useMutation({
    mutationFn: async (form: Partial<UpdateDocumentInput>) => {
      const safeID = docID ?? form.id
      if (!safeID) throw new Error('No document ID provided')
      const res = await graphQLRequest<
        UpdateDocumentMutation,
        UpdateDocumentMutationVariables
      >(updateDocument, {
        input: {
          ...form,
          documentUpdatedById: me.data?.id,
          id: safeID,
        },
      })
      return res.data?.updateDocument ?? {}
    },
    ...getDefaultMutations({ queryKey: ['documentSet'] }, snackbars),
  })
}
