import { fetchAuthSession } from 'aws-amplify/auth'

import { ADMIN_GROUP } from '@/utils/CONSTANTS'

export const isAdmin = async () => {
  const session = await fetchAuthSession()
  const cognitoGroups = session?.tokens?.accessToken.payload?.[
    'cognito:groups'
  ] as string[]

  return cognitoGroups?.includes?.(ADMIN_GROUP) ?? false
}
