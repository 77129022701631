import { useMutation } from '@tanstack/react-query'

import { updatePromptSet } from '@/graphql/mutations'
import { useMySnackbar, useMyUser } from '@/hooks'
import { getDefaultMutations, graphQLRequest } from '@/utils'
import {
  PromptSet,
  UpdatePromptSetInput,
  UpdatePromptSetMutation,
  UpdatePromptSetMutationVariables,
} from '@/utils/api'

export const useUpdatePromptSet = (
  promptSetID?: string,
  orgID?: string | null
) => {
  const me = useMyUser()
  const snackbars = useMySnackbar({
    errorMessage: 'Error updating prompt set',
    successMessage: 'Prompt set updated',
  })
  return useMutation({
    mutationFn: async (data: UpdatePromptSetInput) => {
      const safeOrg = data.organizationPromptSetsId ?? orgID
      const safePromptSetID = data.id ?? promptSetID
      if (!safeOrg || !safePromptSetID) {
        throw new Error('Missing org or prompt set ID')
      }
      const res = await graphQLRequest<
        UpdatePromptSetMutation,
        UpdatePromptSetMutationVariables
      >(updatePromptSet, {
        input: {
          ...data,
          id: safePromptSetID,
          organizationPromptSetsId: safeOrg,
          promptSetUpdatedById: me.data?.id as string,
        },
      })
      return res.data?.updatePromptSet as PromptSet
    },
    ...getDefaultMutations(
      { queryKey: ['promptSet', ...(!!orgID ? [orgID] : [])] },
      snackbars
    ),
  })
}
