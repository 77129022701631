import { useQuery } from '@tanstack/react-query'

import { listNamespaces } from '@/graphql/queries'
import { graphQLRequest } from '@/utils'
import {
  ListNamespacesQuery,
  ListNamespacesQueryVariables,
  Namespace,
} from '@/utils/api'

export const useListDocumentSet = (orgID?: string) => {
  return useQuery({
    enabled: !!orgID,
    queryFn: async () => {
      const res = await graphQLRequest<
        ListNamespacesQuery,
        ListNamespacesQueryVariables
      >(listNamespaces, {
        filter: {
          organizationNamespacesId: {
            eq: orgID,
          },
        },
      })
      return res.data?.listNamespaces?.items as Namespace[]
    },
    queryKey: ['documentSet', orgID],
  })
}
