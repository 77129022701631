import { __, defaultTo, gt, intersection, length, pipe } from 'ramda'
/**
 * Checks if the user has the role of 'Sales' or 'Admin'.
 *
 * @param roles - The roles of the user.
 * @returns True if the user has the role of 'Sales' or 'Admins', false otherwise.
 */
export const isSalesOrAdmin = pipe<
  [string[] | null | undefined],
  string[],
  string[],
  number,
  boolean
>(defaultTo([]), intersection(['Sales', 'Admins']), length, gt(__, 0))

if (import.meta.vitest) {
  const { test, expect } = import.meta.vitest
  test.each([
    { expected: true, input: ['Sales', 'User'] },
    { expected: false, input: ['User', 'Guest'] },
    { expected: true, input: ['Sales', 'Admins'] },
    { expected: true, input: ['Admins'] },
    { expected: false, input: [] },
    { expected: false, input: null },
    { expected: false, input: undefined },
  ])('isSalesOrAdmin($input)', ({ input, expected }) => {
    expect(isSalesOrAdmin(input)).toBe(expected)
  })
}
