import { capitalize, Stack } from '@mui/material'
import { Fragment } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'

import { useListCustomComponent } from '@/screens/ComponentLibrary/hooks'
import { ComponentType } from '@/utils/api'

import { DrawerItem } from '../../DrawerItem'

export type CustomComponentsSubsectionProps = {
  open: boolean
  tooltipOpen: string | null
  onClick: (path: string) => () => void
  onClose: () => void
  onOpen: (title: string) => () => void
}

const customComponentSections: Record<ComponentType, string[]> = {
  dataIntake: ['settings', 'conversation', 'version', 'config'],
  homeBase: ['settings', 'version'],
  inlineSignup: ['settings', 'version'],
  introduction: ['settings', 'version'],
  recipe: ['settings', 'code-editor', 'version'],
  semanticSearch: ['settings', 'questions', 'version'],
  sentimentCheck: ['settings', 'version'],
  userGrouping: ['settings', 'version'],
  [ComponentType.authCode]: ['settings', 'version', 'config'],
  [ComponentType.taskTracker]: ['settings', 'version', 'config'],
}

export const CustomComponentsSubsection = (
  props: CustomComponentsSubsectionProps
) => {
  const { orgID } = useParams()
  const customComponents = useListCustomComponent(orgID)
  const { pathname } = useLocation()
  const isSubsectionOpen = (solutionID: string) =>
    pathname.startsWith(`/${orgID}/component-library/${solutionID}`)
  if (!props.open) return null
  return (
    <Stack>
      {customComponents.data?.slice(0, 5)?.map(({ id, name, type }) => (
        <Fragment key={id}>
          <DrawerItem
            {...props}
            enabled
            iconName={isSubsectionOpen(id) ? 'chevron-down' : 'chevron-right'}
            path={`/${orgID}/component-library/${id}`}
            sx={{ pl: 4.5, px: 0 }}
            title={name}
            onOpen={props.onOpen(name)}
          />
          <Routes>
            <Route
              element={
                <>
                  {customComponentSections?.[type].map((tab) => (
                    <DrawerItem
                      key={tab}
                      {...props}
                      enabled
                      path={`/${orgID}/component-library/${id}?tab=${tab}`}
                      sx={{ pl: 8, px: 0 }}
                      title={capitalize(tab)}
                      onOpen={props.onOpen(name)}
                    />
                  ))}
                </>
              }
              path={`${id}/*`}
            />
          </Routes>
        </Fragment>
      ))}
    </Stack>
  )
}
