import { useQuery } from '@tanstack/react-query'

import { validateConfig } from '@/graphql/queries'
import { AmplifyGraphQLclient } from '@/utils/amplify'

export default function useValidateEndpoint(
  component: any,
  componentID?: string
) {
  return useQuery({
    enabled: !!componentID && !!component,
    queryFn: async () => {
      const validate = (env: string) =>
        AmplifyGraphQLclient.graphql({
          query: validateConfig,
          variables: { component: JSON.stringify(component), env },
        })

      const [lowerError, upperError] = (
        await Promise.all([
          validate(import.meta.env.VITE_APP_LOWER_ENV),
          validate(import.meta.env.VITE_APP_UPPER_ENV),
        ])
      ).map((e) => e.data.validateConfig?.error)
      return [
        {
          env: import.meta.env.VITE_APP_LOWER_ENV,
          error: lowerError,
          valid: !lowerError,
        },
        {
          env: import.meta.env.VITE_APP_UPPER_ENV,
          error: upperError,
          valid: !upperError,
        },
      ]
    },
    queryKey: [
      'component-library',
      componentID,
      'validate',
      JSON.stringify(component),
    ],
  })
}
