import { useMutation } from '@tanstack/react-query'

import { createPromptSet } from '@/graphql/mutations'
import { useMySnackbar, useMyUser } from '@/hooks'
import { getDefaultMutations, graphQLRequest } from '@/utils'
import {
  CreatePromptSetInput,
  CreatePromptSetMutation,
  CreatePromptSetMutationVariables,
} from '@/utils/api'

export const useCreatePromptSet = (orgID?: string) => {
  const me = useMyUser()
  const snackBars = useMySnackbar()
  return useMutation({
    mutationFn: async (data: CreatePromptSetInput) => {
      const res = await graphQLRequest<
        CreatePromptSetMutation,
        CreatePromptSetMutationVariables
      >(createPromptSet, {
        input: {
          ...data,
          organizationPromptSetsId: orgID,
          promptSetUpdatedById: me.data?.id as string,
        },
      })
      return res.data?.createPromptSet
    },
    ...getDefaultMutations({ queryKey: ['promptSet', orgID] }, snackBars),
  })
}
