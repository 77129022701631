import { Box, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { VailAccordion } from '@/components'
import { CreatePromptSetInput, PromptSet } from '@/utils/api'

import { useAccordionStore, useUpdatePromptSet } from '../hooks'
import { PromptSetForm } from './PromptSetForm'

export function PromptSetAccordion(props: PromptSet) {
  const form = useForm<CreatePromptSetInput>({
    defaultValues: {
      chat: props.chat,
      name: props.name,
      prompt: props.prompt,
      type: props.type,
    },
    mode: 'onChange',
  })

  const updatePromptSet = useUpdatePromptSet(
    props.id,
    props.organizationPromptSetsId
  )
  const onSubmit = form.handleSubmit(async (data) => {
    const res = await updatePromptSet.mutateAsync({
      ...data,
      id: data.id ?? props.id,
    })

    form.reset(
      {
        chat: res.chat,
        name: res.name,
        prompt: res.prompt,
      },
      {
        keepDirty: false,
        keepDirtyValues: false,
        keepIsValid: false,
        keepTouched: false,
      }
    )
  })
  const { opened, toggle } = useAccordionStore()
  return (
    <VailAccordion
      expanded={!!opened?.[props.id]}
      title={props.name}
      onChange={() => toggle(props.id)}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={onSubmit}
        >
          <PromptSetForm form={form} />
          <Button sx={{ alignSelf: 'flex-end' }} type="submit">
            Save changes
          </Button>
        </Box>
      </FormProvider>
    </VailAccordion>
  )
}
