import { useQuery } from '@tanstack/react-query'
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth'

import { createCognitoUser } from '@/graphql/mutations'
import { getCognitoUser } from '@/graphql/queries'
import {
  CognitoUser,
  CreateCognitoUserMutation,
  CreateCognitoUserMutationVariables,
  GetCognitoUserQuery,
  GetCognitoUserQueryVariables,
  Permission,
  Theme,
} from '@/utils/api'
import graphQLRequest from '@/utils/graphqlClient'
import { isAdmin } from '@/utils/isAdmin'

export const useMyUser = () => {
  return useQuery({
    queryFn: async () => {
      const { userId: id } = await getCurrentUser()

      const data = await graphQLRequest<
        GetCognitoUserQuery,
        GetCognitoUserQueryVariables
      >(getCognitoUser, { id })

      const dataUser = data.data?.getCognitoUser
      if (dataUser) return dataUser as CognitoUser

      const userAttributes = await fetchUserAttributes()
      const email = userAttributes?.email ?? ''
      const d = await graphQLRequest<
        CreateCognitoUserMutation,
        CreateCognitoUserMutationVariables
      >(createCognitoUser, {
        input: {
          demosShown: [],
          email,
          id,
          name: email,
          role: (await isAdmin()) ? Permission.administrator : Permission.user,
          settings: {
            fontSize: 14,
            theme: window.matchMedia('(prefers-color-scheme: dark)').matches
              ? Theme.dark
              : Theme.light,
          },
        },
      })
      return d.data?.createCognitoUser as CognitoUser
    },

    queryKey: ['my user'],
  })
}
export default useMyUser
