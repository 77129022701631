import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  SxProps,
  Tooltip,
} from '@mui/material'
import { useState } from 'react'
import { NavigateFunction, To, useNavigate } from 'react-router-dom'

import { ButtonLink, Icon } from '@/components'
import { useKey } from '@/hooks'
import { safeCypressID } from '@/utils'
import { ComponentType } from '@/utils/api'

const ButtonSx: SxProps = {
  height: 50,
  width: '100%',
}

const goToRoute =
  (open: boolean, navigate: NavigateFunction, route: To) => () => {
    if (!open) return
    navigate(route)
  }

type FastAccessConfig = {
  route: string
  fastKey: string
  name: string
}
const Components: Record<ComponentType, FastAccessConfig> = {
  [ComponentType.recipe]: {
    fastKey: 'r',
    name: 'Custom Recipe',
    route: './new-recipe',
  },
  [ComponentType.semanticSearch]: {
    fastKey: 's',
    name: 'Semantic Search',
    route: './new-semantic-search',
  },
  [ComponentType.dataIntake]: {
    fastKey: 'd',
    name: 'Data Intake',
    route: './new-data-intake',
  },
  [ComponentType.introduction]: {
    fastKey: 'i',
    name: 'Introduction',
    route: './new-introduction',
  },
  [ComponentType.inlineSignup]: {
    fastKey: 'n',
    name: 'In Line Sign Up',
    route: './new-in-line-sign-up',
  },
  [ComponentType.sentimentCheck]: {
    fastKey: 'c',
    name: 'Sentiment Check',
    route: './new-sentiment-check',
  },
  [ComponentType.homeBase]: {
    fastKey: 'h',
    name: 'Home Base',
    route: './new-home-base',
  },
  [ComponentType.userGrouping]: {
    fastKey: 'u',
    name: 'User Grouping',
    route: './new-user-grouping',
  },
  [ComponentType.authCode]: {
    fastKey: 'a',
    name: 'Auth Code',
    route: './new-auth-code',
  },
  [ComponentType.taskTracker]: {
    fastKey: 't',
    name: 'Task Tracker',
    route: './new-task-tracker',
  },
}

type TooltipLinkProps = FastAccessConfig & { open: boolean }

const TooltipLink = ({ fastKey, name, route, open }: TooltipLinkProps) => {
  const navigate = useNavigate()
  useKey(fastKey, goToRoute(open, navigate, route))
  return (
    <Tooltip
      placement="right"
      title={`Press ${fastKey} to quickly create a new ${name}`}
    >
      <ButtonLink
        id={safeCypressID(`new ${name}`)}
        sx={ButtonSx}
        to={route}
        variant="outlined"
      >
        {name} ({fastKey})
      </ButtonLink>
    </Tooltip>
  )
}

export const NewComponentDialog = () => {
  const [open, setOpen] = useState(false)
  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  // useKey('r', goToRoute(open, navigate, './new-recipe'))
  // useKey('h', goToRoute(open, navigate, './new-home-base'))
  // useKey('s', goToRoute(open, navigate, './new-semantic-search'))
  // useKey('i', goToRoute(open, navigate, './new-introduction'))
  // useKey('n', goToRoute(open, navigate, './new-in-line-sign-up'))
  // useKey('c', goToRoute(open, navigate, './new-sentiment-check'))

  return (
    <>
      <Button
        id="new_custom_component_dialog"
        startIcon={<Icon name="add" />}
        variant="contained"
        onClick={openDialog}
      >
        Component
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Create a new custom component</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {Object.values(Components).map((config) => (
            <TooltipLink {...config} key={config.name} open={open} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}
