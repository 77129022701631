import { Box, capitalize, Stack, Typography } from '@mui/material'
import { When } from 'react-if'

import { Breadcrumbs, BreadcrumbsItem } from '@/components'
import { useDocTitle, useUrlParams } from '@/hooks'
import { formatDate } from '@/utils'
import { WithChildren } from '@/utils/types'

export type PageHeaderProps = WithChildren<{
  title: string
  breadcrumbs?: BreadcrumbsItem[]
  updatedOn?: string
  updatedBy?: string
}>
export const PageHeader = (props: PageHeaderProps) => {
  const [{ tab }] = useUrlParams()

  useDocTitle(`${props.title} ${!!tab ? `| ${capitalize(tab)}` : ''}`)
  return (
    <Stack
      alignItems={['flex-start', 'center']}
      direction={['column', 'row']}
      gap={[4, 0]}
      justifyContent="space-between"
      my={3}
    >
      <Box>
        <When condition={!!props.breadcrumbs}>
          <Breadcrumbs
            items={[
              ...(props.breadcrumbs ?? []),
              { link: '.', name: props.title },
            ]}
          />
        </When>
        <Typography id="title" variant="h4">
          {props.title}
        </Typography>
        <When condition={!!props.updatedOn}>
          <Typography variant="caption">
            Edited {formatDate(props.updatedOn ?? '')}{' '}
            {props.updatedBy ? ` by ${props.updatedBy}` : null}
          </Typography>
        </When>
      </Box>
      <Stack direction="row" gap={1} justifyContent="end">
        {props.children}
      </Stack>
    </Stack>
  )
}
