import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Fragment } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Icon } from '@/components'
import { useSwitch } from '@/hooks'
import { CreatePromptSetInput, PromptSetTypes } from '@/utils/api'

import { PromptSetForm } from '../components'
import { useCreatePromptSet } from '../hooks'

export const CreatePromptSetModal = () => {
  const { orgID } = useParams()

  const [open, flipOpen] = useSwitch(false)
  const createPromptSet = useCreatePromptSet(orgID)
  const form = useForm<CreatePromptSetInput>()

  const onSubmit = form.handleSubmit(async (data) => {
    await createPromptSet.mutateAsync(data)
    flipOpen()
    form.reset({})
  })

  return (
    <>
      <Button
        startIcon={<Icon name="add" />}
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => {
          form.reset({
            name: '',
            prompt: '',
            type: undefined,
          })
          flipOpen()
        }}
      >
        Prompt Set
      </Button>
      <Dialog
        fullWidth
        component="form"
        maxWidth={
          form.watch('type') === PromptSetTypes.promptTemplate ? 'sm' : 'md'
        }
        open={open}
        onSubmit={onSubmit}
      >
        <DialogTitle>Edit Prompt Template</DialogTitle>
        <FormProvider {...form}>
          <DialogContent>
            <PromptSetForm form={form} />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={flipOpen}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  )
}
