import { capitalize } from '@mui/material'
import { useMutation } from '@tanstack/react-query'

import { createOrganization } from '@/graphql/mutations'
import useMySnackbar from '@/hooks/useSnackbar'
import {
  CreateOrganizationInput,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  orgStatus,
} from '@/utils/api'
import graphQLRequest from '@/utils/graphqlClient'
import getDefaultMutations from '@/utils/reactQueryUtils'

import useMyUser from '../useMyUser'

export const useCreateOrg = () => {
  const snackbars = useMySnackbar({
    errorMessage: 'Error creating org',
    successMessage: 'Org created successfully',
  })
  const me = useMyUser()
  return useMutation({
    mutationFn: async (newOrg: CreateOrganizationInput) => {
      const response = await graphQLRequest<
        CreateOrganizationMutation,
        CreateOrganizationMutationVariables
      >(createOrganization, {
        input: {
          ...newOrg,
          name: capitalize((newOrg.name || newOrg.slug) ?? ''),
          orgStatus: orgStatus.active,
          organizationUpdatedById: me.data?.id as string,
          slug: newOrg.name?.toLowerCase(),
        },
      })

      return response.data?.createOrganization
    },
    mutationKey: ['createOrg'],
    ...getDefaultMutations({ queryKey: ['bla'] }, snackbars),
  })
}
