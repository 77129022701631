import { pick } from 'ramda'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface AccordionState {
  opened: Record<string, boolean>
  toggle: (id: string) => void
}

export const useAccordionStore = create<AccordionState>()(
  persist(
    (set, get) => ({
      opened: {},
      toggle: (id) =>
        set({
          opened: {
            ...get().opened,
            [id]: !get().opened?.[id],
          },
        }),
    }),
    {
      name: 'namespace-accordions',
      partialize: (state) => pick(['opened'], state),
    }
  )
)
