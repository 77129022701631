import { Navigate, Route, Routes } from 'react-router-dom'

import ListTemplates from './ListTemplates'

const Templates = () => (
  <Routes>
    <Route element={<ListTemplates />} path="/" />
    <Route element={<Navigate replace to="/error/404" />} path="*" />
  </Routes>
)

export default Templates
