import { useSnackbar as useSnackbarNotistack } from 'notistack'

import { safeCypressID } from '@/utils'

export type SnackbarParam =
  | string
  | { message: string; autoHideDuration: number }

type UseMySnackbarParams = {
  errorMessage?: SnackbarParam
  infoMessage?: SnackbarParam
  successMessage?: SnackbarParam
}

export type ReturnSnackbars = {
  setErrorSnackbar: (message?: SnackbarParam) => void
  setInfoSnackbar: (message?: SnackbarParam) => void
  setSuccessSnackbar: (message?: SnackbarParam) => void
}

const isString = (value: any): value is string => typeof value === 'string'

const getMessage = (param?: SnackbarParam, local?: SnackbarParam) => {
  if (isString(local)) return local
  if (isString(param)) return param
  return param?.message ?? local?.message
}
/**
 * The messages that you pass can be overridden via the callback or the config in the declaration of the hook.
 * @param params
 */
export const useMySnackbar = (
  params?: UseMySnackbarParams
): ReturnSnackbars => {
  const { errorMessage, infoMessage, successMessage } = params || {}

  const { enqueueSnackbar } = useSnackbarNotistack()

  const setInfoSnackbar = (message?: SnackbarParam) => {
    const msg = getMessage(infoMessage, message)
    if (!msg) return
    enqueueSnackbar(msg, {
      SnackbarProps: {
        id: safeCypressID(`snackbar_info_${msg}`),
      },
      autoHideDuration: 2000,
      variant: 'info',
    })
  }

  const setSuccessSnackbar = (message?: SnackbarParam) => {
    const msg = getMessage(successMessage, message)
    if (!msg) return

    enqueueSnackbar(msg, {
      SnackbarProps: {
        id: safeCypressID(`snackbar_success_${msg}`),
      },
      variant: 'success',
    })
  }

  const setErrorSnackbar = (message?: SnackbarParam) => {
    const msg = getMessage(errorMessage, message)
    if (!msg) return

    enqueueSnackbar(msg, {
      SnackbarProps: {
        id: safeCypressID(`snackbar_error_${msg}`),
      },
      variant: 'error',
    })
  }

  return { setErrorSnackbar, setInfoSnackbar, setSuccessSnackbar }
}
export default useMySnackbar

if (import.meta.vitest) {
  const { describe, it } = import.meta.vitest
  describe('useMySnackbar utils', () => {
    it.each([
      ['Esteban Trillo', true],
      [0, false],
      [{}, false],
      [[], false],
      [null, false],
    ])('isString(%s)', (param, expected) => {
      expect(isString(param)).toBe(expected)
    })

    it.each([
      { expected: 'Hello', local: 'Hello', param: 'World' },
      { expected: 'World', local: { message: 'Hello' }, param: 'World' },
      {
        expected: 'John',
        local: { message: 'Mister' },
        param: { message: 'John' },
      },
      {
        expected: 'John',
        local: undefined,
        param: { message: 'John' },
      },
      {
        expected: 'Doe',
        local: { message: 'Doe' },
        param: undefined,
      },
      {
        expected: undefined,
        local: { title: 'doe' },
        param: { title: 'John' },
      },
    ])('getMessage(%o,%o)', ({ param, local, expected }) => {
      // We are ignoring the TS error here because it's a test for checking the the function works correctly regardless
      // @ts-ignore
      expect(getMessage(param, local)).toBe(expected)
    })
  })
}
