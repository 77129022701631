import { styled } from '@mui/material'

const St4 = styled('path')`
  fill: #fff;
`
const Svg = styled('svg')`
  width: auto;
  height: 2.5rem;
`

export const LifelinkLogo = () => (
  <Svg
    id="Layer_1"
    version="1.1"
    viewBox="0 0 2924 1024"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
  >
    <path
      d="M412.3 262.8h471.6v471.6H412.3z"
      fill="#3faaac"
      transform="rotate(-45 648.14 498.62)"
    />
    <path
      d="M135.9 262.8h471.6v471.6H135.9z"
      fill="#9dcad4"
      transform="rotate(-45 371.7 498.6)"
    />
    <path
      d="M648.1 165.1L509.9 303.4 371.7 165.1 38.2 498.6h943.4z"
      fill="#fff"
      opacity=".14"
    />
    <path
      d="M371.8 360.8h276.1v276.1H371.8z"
      fill="#f9ed25"
      transform="rotate(-45 509.87 498.86)"
    />
    <St4 d="M1205.8 663.1V305.6h77.4v357.5h-77.4z" />
    <St4 d="M1351.1 305.6h79.1v65.2h-79.1v-65.2zm.9 357.5V413.2h77.3v249.9H1352z" />
    <St4 d="M1521 465.8h-44.2v-52.6h44.2v-27.4c0-52.6 27-85.2 90.3-85.2 14-.1 28 1.7 41.4 5.4v53.9c-6.7-1.6-13.6-2.4-20.5-2.3-26.1 0-34 8.8-34 29.8v26h54.4V466h-54.4v197.3H1521V465.8z" />
    <St4 d="M1910.4 624c-22.3 26.5-53.9 44.2-105.6 44.2-88.5 0-133.1-52.6-133.1-129.4 0-75.5 44.3-130.8 126.6-130.8 81.4 0 121 53.5 121 122.9.2 9.8-.4 19.6-1.8 29.3H1749c4.2 32.3 20 52.6 58.2 52.6 22.4.3 43.5-10.5 56.3-28.9l46.9 40.1zm-66.1-109.8c-2.8-31.2-14.9-50.7-46.5-50.7-30.3 0-45.7 19.5-48.9 50.7h95.4z" />
    <St4 d="M1985.6 663.1V305.6h77.3v357.5h-77.3z" />
    <St4 d="M2130.9 305.6h79.1v65.2h-79.1v-65.2zm.9 357.5V413.2h77.2v249.9h-77.2z" />
    <St4 d="M2277.9 413.2h71.7l1.4 34.4h.9a85.5 85.5 0 0175-39.6c52.6 0 87.1 34 87.1 85.2v169.9h-77.2V501.4c0-20-12.6-35.8-36.3-35.8-28.4 0-45.2 19.1-45.2 45.2V663H2278l-.1-249.8z" />
    <St4 d="M2679.7 559.8h-20v103.3h-77.4V305.6h77.3v201.5h20l52.6-94h88.4l-75.5 119.6 79.2 130.3h-89.4l-55.2-103.2z" />
    <St4 d="M2827.4 320.3V289h-12.1v-6.8h32.9v6.8h-12.1v31.3h-8.7z" />
    <St4 d="M2893.3 282.2v38.1h-8.6v-25.2l-8.2 25.2h-7.3l-8.2-25.1v25.1h-8.5v-38.1h11.8l8.8 27.4 8.7-27.4h11.5z" />
    <St4 d="M1903.1 840.4l14.3-15.5a50.4 50.4 0 0037.2 15.7c18.1 0 26.1-7.1 26.1-18 0-12-11.3-15.2-30.7-19.7-21.6-5.4-42.5-12.7-42.5-37.7 0-22.4 17.2-38.6 47.9-38.6 17.5-.7 34.5 5.9 47.1 18.1l-13.3 15.7a46.74 46.74 0 00-34.1-14.7c-15.7 0-23.4 7.4-23.4 17.2 0 11.8 10.8 14.3 29.9 19 22.7 5.8 43.1 12.8 43.1 38.5 0 23.3-17.8 39.5-50.2 39.5-22.1-.2-38.8-6.9-51.4-19.5z" />
    <St4 d="M2144.9 728.4L2098 807v50.9h-24v-50.6l-46.9-78.9h27l31.9 56.5h.3l32.3-56.5h26.3z" />
    <St4 d="M2163.6 840.4l14.3-15.5a50.4 50.4 0 0037.2 15.7c18.1 0 26.1-7.1 26.1-18 0-12-11.3-15.2-30.7-19.7-21.6-5.4-42.5-12.7-42.5-37.7 0-22.4 17.2-38.6 47.9-38.6 17.5-.7 34.5 5.9 47.1 18.1l-13.3 15.7a46.74 46.74 0 00-34.1-14.7c-15.7 0-23.4 7.4-23.4 17.2 0 11.8 10.8 14.3 29.9 19 22.7 5.8 43.1 12.8 43.1 38.5 0 23.3-17.9 39.5-50.3 39.5-22.2-.2-38.8-6.9-51.3-19.5z" />
    <St4 d="M2329.5 857.9V748h-42.9v-19.6h109.6V748h-42.8v109.9h-23.9z" />
    <St4 d="M2427.8 857.9V728.5h86.8v19.4h-63.1v35.3h55.2v19.2h-55v36.3h64.3V858l-88.2-.1z" />
    <St4 d="M2685.7 728.4v129.4H2662v-94.7h-.4l-33.4 94.8h-20.9l-33.4-94.2h-.4v94.2h-23.1V728.5h33.2l34.4 100h.4l34.4-100 32.9-.1z" />
    <St4 d="M2718.2 840.4l14.3-15.5a50.46 50.46 0 0037.3 15.7c18 0 26.1-7.1 26.1-18 0-12-11.3-15.2-30.7-19.7-21.6-5.4-42.5-12.7-42.5-37.7 0-22.4 17.2-38.6 47.9-38.6 17.5-.7 34.5 5.9 47.1 18.1l-13.3 15.7a46.74 46.74 0 00-34.1-14.7c-15.7 0-23.4 7.4-23.4 17.2 0 11.8 10.8 14.3 29.9 19 22.7 5.8 43.1 12.8 43.1 38.5 0 23.3-17.9 39.5-50.3 39.5-22.3-.2-38.9-6.9-51.4-19.5z" />
  </Svg>
)
