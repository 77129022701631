import { Avatar, Typography } from '@mui/material'
import { Else, If, Then } from 'react-if'
import { Route, Routes, useParams } from 'react-router-dom'

import { OrgPublicImage } from '@/components/OrgLogo'
import { useOrg } from '@/hooks'

import { DrawerItem } from '../DrawerItem'
import {
  CustomComponentsSubsection,
  LLMSubsection,
  SolutionsSubsection,
} from './subsections'

export type OrgDrawerProps = {
  open: boolean
  toggleDrawer?: () => void
  tooltipOpen: string | null
  onClick: (path: string) => () => void
  onClose: () => void
  onOpen: (title: string) => () => void
}
export const OrgDrawer = (props: OrgDrawerProps) => {
  const { orgID } = useParams()
  const org = useOrg(orgID)
  return (
    <div>
      <If condition={props.open}>
        <Then>
          <Typography noWrap mb={2} textAlign="center" variant="h5">
            {org.data?.name ?? 'Loading...'}
          </Typography>
        </Then>
        <Else>
          <Avatar sx={{ mx: 'auto' }}>
            <OrgPublicImage imageKey={`${orgID}/logo`} orgID={orgID} />
          </Avatar>
        </Else>
      </If>

      <DrawerItem
        enabled
        iconName="settings"
        open={props.open}
        path={`/${orgID}/organization`}
        title="Settings"
        tooltipOpen={props.tooltipOpen}
        onClose={props.onClose}
        onOpen={props.onOpen('Settings')}
      />

      <DrawerItem
        enabled
        iconName="ai"
        open={props.open}
        path={`/${orgID}/llm`}
        title="LLM@LLS"
        tooltipOpen={props.tooltipOpen}
        onClose={props.onClose}
        onOpen={props.onOpen('LLM@LLS')}
      />
      <Routes>
        <Route
          element={
            <LLMSubsection
              open={props.open}
              tooltipOpen={props.tooltipOpen}
              onClick={props.onClick}
              onClose={props.onClose}
              onOpen={props.onOpen}
            />
          }
          path="llm/*"
        />
      </Routes>
      <DrawerItem
        enabled
        iconName="star-outlined"
        open={props.open}
        path={`/${orgID}/solutions`}
        title="Solutions"
        tooltipOpen={props.tooltipOpen}
        onClose={props.onClose}
        onOpen={props.onOpen('Solutions')}
      />
      <Routes>
        <Route
          element={
            <SolutionsSubsection
              open={props.open}
              tooltipOpen={props.tooltipOpen}
              onClick={props.onClick}
              onClose={props.onClose}
              onOpen={props.onOpen}
            />
          }
          path="solutions/*"
        />
      </Routes>
      <DrawerItem
        enabled
        iconName="eg"
        open={props.open}
        path={`/${orgID}/engagement-goals`}
        title="Engagement Goals"
        tooltipOpen={props.tooltipOpen}
        onClose={props.onClose}
        onOpen={props.onOpen('engagement-goals')}
      />
      <DrawerItem
        enabled
        iconName="construction"
        open={props.open}
        path={`/${orgID}/component-library`}
        title="Component Library"
        tooltipOpen={props.tooltipOpen}
        onClose={props.onClose}
        onOpen={props.onOpen('component-library')}
      />
      <Routes>
        <Route
          element={
            <CustomComponentsSubsection
              open={props.open}
              tooltipOpen={props.tooltipOpen}
              onClick={props.onClick}
              onClose={props.onClose}
              onOpen={props.onOpen}
            />
          }
          path="component-library/*"
        />
      </Routes>
    </div>
  )
}
