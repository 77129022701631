import { QueryFilters } from '@tanstack/react-query'

import { ReturnSnackbars } from '@/hooks/useSnackbar/useSnackbar'
import queryClient from '@/utils/queryClient'

export const getDefaultMutations = (
  queryKey: QueryFilters,
  snackBars?: ReturnSnackbars
) => ({
  onError: () => {
    snackBars?.setErrorSnackbar?.()
  },
  onMutate: () => {
    snackBars?.setInfoSnackbar()
    return queryClient.cancelQueries(queryKey)
  },
  onSettled: () => queryClient.invalidateQueries(queryKey),
  onSuccess: () => {
    snackBars?.setSuccessSnackbar?.()
  },
})
export default getDefaultMutations
