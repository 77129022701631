import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { ConfirmProvider } from '@/components'

import { DocumentSetAccordion } from '../components'
import { useListDocumentSet } from '../hooks'
import { CreateDocumentSet } from '../Modals'

export function DocumentSet() {
  const { orgID } = useParams()
  const sets = useListDocumentSet(orgID)

  return (
    <ConfirmProvider>
      <Stack>
        {sets.data?.map((set) => (
          <DocumentSetAccordion key={set.id} {...set} />
        ))}
        <CreateDocumentSet orgId={orgID} />
      </Stack>
    </ConfirmProvider>
  )
}
