// @ts-nocheck
import { addSmallestNumber } from './addSmallestNumber'
export const parseCSV = (rawCSV: unknown[]): Record<string, unknown>[] => {
  const keys = rawCSV[0]
  const reducedCSV = rawCSV.slice(1).reduce((acc, curr) => {
    const obj: Record<string, unknown> = {}
    curr.forEach((c, idx) => {
      return (obj[keys[idx]] = typeof c === 'string' ? c.trim() : c)
    })
    return [...acc, obj]
  }, [])
  if (reducedCSV.find((r) => !r.key)) {
    const keys = reducedCSV.map((r) => r.key)
    return reducedCSV.map((r, idx) => ({
      ...r,
      key: r.key || addSmallestNumber(keys)?.[idx],
    }))
  }
  return reducedCSV
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest
  const defaultObj = [
    ['key', 'question', 'answerText', 'source', 'suggestedQuestions'],
    [
      '     1',
      'This is the question text',
      'This is the question answer',
      'Generated by Vail',
      '0',
    ],
  ]
  it('should add the missing keys as the next available number', () => {
    const parsed = parseCSV([
      ...defaultObj,
      [
        '     ',
        'This is another question text',
        'This is another question answer',
        'Generated by Vail',
        '0',
      ],
    ])

    expect(parsed).toStrictEqual([
      {
        answerText: 'This is the question answer',
        key: '1',
        question: 'This is the question text',
        source: 'Generated by Vail',
        suggestedQuestions: '0',
      },
      {
        answerText: 'This is another question answer',
        key: '2',
        question: 'This is another question text',
        source: 'Generated by Vail',
        suggestedQuestions: '0',
      },
    ])
  })

  it('parses a common CSV with any type of keys', () => {
    expect(parseCSV(defaultObj)).toMatchInlineSnapshot(`
      [
        {
          "answerText": "This is the question answer",
          "key": "1",
          "question": "This is the question text",
          "source": "Generated by Vail",
          "suggestedQuestions": "0",
        },
      ]
    `)
  })
  it('cleans the inputs of the CSV', () => {
    const parsed = parseCSV([
      ['key', 'question', 'answerText', 'source', 'suggestedQuestions'],
      ['     1', '', 'This is the question answer', 'Generated by Vail', '0'],
    ])
    for (const item of parsed) {
      for (const key in item) {
        //@ts-ignore
        expect(item[key]).toEqual(item[key].trim())
      }
    }
  })
}
