import {
  Box,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  styled,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import { Icon } from '../Icon'
import { ButtonLink } from '../Link'
import { drawerWidth } from './BaseNavigation'
import DrawerMenu from './DrawerMenu'

const openedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: drawerWidth,
})

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

// TODO make the arrow go left
// TODO make the drawer on the left have the same work the drawer as the solution works
const PermanentDrawer = ({
  open,
  toggleDrawer,
}: {
  open: boolean
  toggleDrawer: () => void
}) => (
  <Drawer open={open} variant="permanent">
    <Toolbar>
      <Routes>
        <Route
          element={
            <ButtonLink
              startIcon={<Icon name="chevron-left" />}
              sx={{ mx: 'auto' }}
              to="/"
              variant="text"
            >
              Home
            </ButtonLink>
          }
          path="*"
        />
        <Route
          element={
            <Typography noWrap variant="h6">
              Builder
            </Typography>
          }
          path="/"
        />
      </Routes>
    </Toolbar>
    <Divider />
    <DrawerMenu open={open} />
    <Box
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        marginBottom: '1rem',
      }}
    >
      <IconButton id="open-close-drawer" onClick={toggleDrawer}>
        <Icon name={open ? 'chevron-left' : 'chevron-right'} />
        {/* {open ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
      </IconButton>
    </Box>
  </Drawer>
)

export default PermanentDrawer
