import { filter, flatten, length, lt, pickBy, pipe, values } from 'ramda'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useOrg } from '@/hooks'
import useValidateEndpoint from '@/hooks/useValidate'
import { ConfigDataScreen } from '@/screens/ComponentLibrary/components'
import { useGetCustomComponent } from '@/screens/ComponentLibrary/hooks'
import { flattenObj, parseComponentTypes } from '@/utils'
import { ComponentType } from '@/utils/api'

import { AuthCodeSettings } from './Settings'

export type AuthCodeData =
  | { settings: AuthCodeSettings }
  | string
  | null
  | undefined

const isLanguageEmpty = pipe(
  flattenObj,
  values,
  flatten,
  filter(Boolean),
  length,
  lt(0)
)

const omitIfEmpty = (val: Record<string, unknown>, key: string) => {
  const validKeys = [
    'authCodeErrorInstructions',
    'challengeFailedText',
    'challengeRetryText',
    'introText',
    'lastNamePrompt',
    'type',
    'yearOfBirthConstraintResults',
    'yearOfBirthPrompt',
  ]
  if (!validKeys.includes(key)) return false
  return isLanguageEmpty(val)
}

export const useParseAuthCodeData = (orgSlug?: string, param?: AuthCodeData) =>
  useMemo(() => {
    if (!orgSlug || !param) return {}
    const data = parseComponentTypes(param, ComponentType.authCode)

    if (!data) return {}
    if (typeof data.settings.authCodeErrorInstructions === 'string')
      return {
        error: 'Component out of date',
        message:
          "Please click 'Save' in the settings tab to update the component",
      }

    const filteredSettings: AuthCodeSettings = pickBy(
      omitIfEmpty,
      data.settings
    )

    return {
      data: {
        ...filteredSettings,
        type: filteredSettings.type,
      },
      organization: orgSlug,
      schemaName: 'AuthCodeLoginConfig',
      schemaVersion: '1.0.0',
      version: '1.0.0',
    }
  }, [param, orgSlug])

export const ConfigData = () => {
  const { orgID, componentID } = useParams()
  const org = useOrg(orgID)

  const current = useGetCustomComponent<AuthCodeSettings>(componentID)
  const config = useParseAuthCodeData(org.data?.slug, current.data)
  const valid = useValidateEndpoint(config, componentID)
  return (
    <ConfigDataScreen
      config={config}
      current={current}
      org={org}
      rootName="Auth Code"
      valid={valid}
    />
  )
}
