import { StorageImage } from '@aws-amplify/ui-react-storage'
import { Avatar } from '@mui/material'
import { head } from 'ramda'
import { Else, If, Then } from 'react-if'

import { useImage, useOrg } from '@/hooks'

export type OrgPublicImageProps = {
  orgID?: string
  imageKey: string
}

export const OrgPublicImage = ({ orgID, imageKey }: OrgPublicImageProps) => {
  const org = useOrg(orgID)
  const key = useImage((s) => s.keys?.[imageKey])
  const setKey = useImage((s) => s.setKey)
  if (key === undefined) setKey(imageKey, imageKey)
  if (!orgID) return null

  return (
    <Avatar>
      <If condition={!key}>
        <Then>
          {org.data?.name
            .split(' ')
            .slice(0, 2)
            .map(head)
            .join('')
            .toUpperCase() ?? 'P'}
        </Then>
        <Else>
          <StorageImage
            alt={`${org.data?.name} logo`}
            path={`public/${key}`}
            onGetUrlError={() => setKey(imageKey, null)}
          />
        </Else>
      </If>
    </Avatar>
  )
}
