import { create } from 'zustand'

import { connectStoreToRedux } from '@/utils'

type AppsyncRefetch = {
  refetching: Record<string, boolean>
  setRefetch: (key?: string, fetching?: boolean) => void
  refetchStatus: (key?: string) => boolean
}

export const useAppsyncRefetch = create<AppsyncRefetch>((set, get) => ({
  refetchStatus: (key) => (key ? get().refetching?.[key] ?? false : false),
  refetching: {},
  setRefetch: (key, refetching) =>
    set((state) => ({
      refetching: {
        ...state.refetching,
        ...(!!key ? { [key]: refetching ?? false } : {}),
      },
    })),
}))
connectStoreToRedux('appsyncRefetch', useAppsyncRefetch)
