import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 1 minute
      staleTime: 60000,
    },
  },
})

export default queryClient
