import { useQuery } from '@tanstack/react-query'
import { GraphQLResult } from 'aws-amplify/api'
import { mergeRight } from 'ramda'

import { listCustomComponents } from '@/graphql/queries'
import { useUrlParams } from '@/hooks'
import {
  CustomComponent,
  ListCustomComponentsQuery,
  ListCustomComponentsQueryVariables,
  Visibility,
} from '@/utils/api'
import graphQLRequest from '@/utils/graphqlClient'

import { storeKey } from './CONSTANTS'

export const useListCustomComponent = (
  orgID?: string,
  filter?: ListCustomComponentsQueryVariables['filter'],
  extraKey?: string[],
  fetchAll = true
) => {
  const [{ archiveStatus = Visibility.active }] = useUrlParams()
  return useQuery({
    enabled: !!orgID,

    queryFn: async () => {
      try {
        let items: CustomComponent[] = []
        let finishedGettingHistory = !fetchAll
        let nextToken
        do {
          const req: GraphQLResult<ListCustomComponentsQuery> =
            await graphQLRequest<
              ListCustomComponentsQuery,
              ListCustomComponentsQueryVariables
            >(listCustomComponents, {
              filter: mergeRight(
                {
                  ...(archiveStatus === Visibility.archived
                    ? { visibility: { eq: Visibility.archived } }
                    : { visibility: { ne: Visibility.archived } }),
                  organizationCustomComponentsId: { eq: orgID },
                },
                filter || {}
              ),
              nextToken,
            })
          items = [
            ...items,
            ...(req.data?.listCustomComponents?.items ?? []),
          ] as CustomComponent[]
          nextToken = req.data.listCustomComponents?.nextToken
          if (!req.data.listCustomComponents?.nextToken)
            finishedGettingHistory = true
        } while (!finishedGettingHistory)

        return (items?.filter(Boolean) as CustomComponent[]) ?? []
      } catch (e) {
        return []
      }
    },

    queryKey: [storeKey, orgID, archiveStatus, ...(extraKey ?? [])],
  })
}
