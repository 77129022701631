import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { ReactNode, SyntheticEvent } from 'react'
import { Else, If, Then } from 'react-if'

import { Icon, MuiIconNames } from '@/components'
import { useUrlParams } from '@/hooks'
import { safeCypressID } from '@/utils'

export type VailAccordionProps = AccordionProps & {
  selectOpenWithUrl?: boolean
  detailsSx?: SxProps
  useDefaultBox?: boolean
  id?: string
  subTitle?: string
  expandIcon?: MuiIconNames
  startContent?: ReactNode
  endContent?: ReactNode
}

/**
 * This is the new accordion that we are going to use as a replacement of the sections in the settings pages
 * @param props
 * @constructor
 */
export function VailAccordion(props: VailAccordionProps) {
  const {
    id,
    title = '',
    expanded,
    onChange,
    selectOpenWithUrl = false,
    useDefaultBox = true,
    detailsSx = {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    defaultExpanded,
    subTitle,
    expandIcon,
    startContent,
    endContent,
  } = props

  const [urlParams, setParams] = useUrlParams()

  const openBasedOnParams = urlParams?.[id ?? title] === 'true'
  const onChangeWithUrl = (event: SyntheticEvent, isExpanded: boolean) => {
    setParams({ [id ?? title]: isExpanded ? isExpanded : null })
    onChange?.(event, isExpanded)
  }
  const accordionExpanded = selectOpenWithUrl ? openBasedOnParams : expanded
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={accordionExpanded}
      sx={props?.sx}
      onChange={selectOpenWithUrl ? onChangeWithUrl : onChange}
    >
      <AccordionSummary
        aria-controls={`${title}-content`}
        expandIcon={<Icon name={expandIcon || 'expand-more'} />}
        id={safeCypressID(`${title}-header`)}
      >
        <If condition={!!subTitle}>
          <Then>
            <Stack>
              {props?.startContent}
              <Typography
                fontWeight="bold"
                id={safeCypressID(`${title}-title`)}
              >
                {title}
              </Typography>
              <Typography
                fontWeight="300"
                id={safeCypressID(`${title}-subtitle`)}
                ml={2}
              >
                {subTitle}
              </Typography>
              {props?.endContent}
            </Stack>
          </Then>
          <Else>
            <Typography fontWeight="bold" id={safeCypressID(`${title}-title`)}>
              {title}
            </Typography>
          </Else>
        </If>
      </AccordionSummary>
      <AccordionDetails>
        <If condition={useDefaultBox}>
          <Then>
            <Box sx={detailsSx}>{props.children}</Box>
          </Then>
          <Else>{props.children}</Else>
        </If>
      </AccordionDetails>
    </Accordion>
  )
}
