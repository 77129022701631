import { lazy } from 'react'

import withSuspense from '@/hocs/withSuspense'

export const RecipeTextEditor = withSuspense(
  lazy(() => import('./RecipeTextEditor'))
)

export default RecipeTextEditor
export * from './useRecipeTextEditor'
