import { List } from '@mui/material'
import { useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { MuiIconNames } from '@/components'
import { useIsAdmin } from '@/hooks'

import { DrawerItem } from './DrawerItem'
import { OrgDrawer, OrgDrawerProps } from './OrgDrawer'

export type nonOrgRoutes = {
  iconName: MuiIconNames
  path: string
  title: string
  enabled?: boolean
}

const NonOrgDrawer = ({
  open,
  tooltipOpen,
  onClose,
  onOpen,
  onClick,
}: OrgDrawerProps) => {
  const isAdmin = useIsAdmin()

  const items = useMemo(() => {
    const base: nonOrgRoutes[] = [
      {
        iconName: 'organization',
        path: '/',
        title: 'Organizations',
      },
      {
        iconName: 'templates',
        path: '/templates',
        title: 'Templates',
      },
    ]
    if (isAdmin.data === true) {
      base.push({
        iconName: 'people',
        path: '/users',
        title: 'Users',
      })
      base.push({
        iconName: 'add-to-drive',
        path: '/new-org',
        title: 'Create new org',
      })
    }
    if (import.meta.env.DEV)
      base.push({
        iconName: 'admin',
        path: '/admin',
        title: 'Admin action',
      })
    return base
  }, [isAdmin.data])
  return (
    <>
      {items.map(({ iconName, title, path, enabled = true }) => (
        <DrawerItem
          key={title}
          enabled={enabled}
          iconName={iconName}
          open={open}
          path={path}
          title={title}
          tooltipOpen={tooltipOpen}
          onClose={onClose}
          onOpen={onOpen(title)}
        />
      ))}
    </>
  )
}
const DrawerMenu = ({
  open,
  toggleDrawer,
}: {
  open: boolean
  toggleDrawer?: () => void
}) => {
  const navigate = useNavigate()
  const goToScreen = (path: string) => () => {
    toggleDrawer?.()
    navigate(path)
  }
  const [tooltipOpen, setTooltipOpen] = useState<string | null>(null)

  const onClose = () => setTooltipOpen(null)
  const onOpen = (title: string) => () => setTooltipOpen(title)

  return (
    <List>
      <Routes>
        {import.meta.env.DEV && (
          <Route
            element={
              <NonOrgDrawer
                open={open}
                toggleDrawer={toggleDrawer}
                tooltipOpen={tooltipOpen}
                onClick={goToScreen}
                onClose={onClose}
                onOpen={onOpen}
              />
            }
            path="/admin"
          />
        )}
        <Route
          element={
            <OrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path={':orgID/*'}
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="/new-org"
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="/"
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="/new/organization"
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="users"
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="profile"
        />
        <Route
          element={
            <NonOrgDrawer
              open={open}
              toggleDrawer={toggleDrawer}
              tooltipOpen={tooltipOpen}
              onClick={goToScreen}
              onClose={onClose}
              onOpen={onOpen}
            />
          }
          path="templates"
        />
      </Routes>
    </List>
  )
}

export default DrawerMenu
