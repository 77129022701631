import { Component, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
type StateComponent = { hasError: boolean; message: string; info: unknown }

class DefaultErrorBoundary extends Component<
  { children: ReactNode },
  StateComponent
> {
  displayName = 'withErrorBoundary'
  state: StateComponent = { hasError: false, info: {}, message: '' }

  componentDidCatch(error: Error, errorInfo: unknown) {
    // Send the error log to somewhere
    console.error(error, errorInfo)
    this.setState({ hasError: true, info: errorInfo, message: error.message })
  }

  render() {
    if (
      import.meta.env.MODE !== 'development' &&
      this.state.hasError &&
      !window.location.pathname.includes('/error')
    ) {
      if (
        this.state.message
          .toLowerCase()
          .includes('failed to fetch dynamically imported module')
      ) {
        window.location.reload()
        return null
      }
      // We need to remove the error from the state to allow the user to
      // move to another page
      this.setState({ hasError: false })
      return <Navigate state={this.state} to="/error/500" />
    }

    return <>{this.props.children}</>
  }
}

export default DefaultErrorBoundary
