import { withAuthenticator } from '@aws-amplify/ui-react'
import { SignOut } from '@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Logout as LogoutLogo,
  Menu as MenuIcon,
  Person as PersonIcon,
} from '@mui/icons-material'
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'
import { Else, If, Then, Unless } from 'react-if'
import { useNavigate } from 'react-router-dom'

import { Link } from '@/components'
import useMyUser from '@/hooks/useMyUser'
import { useSmallScreen } from '@/hooks/useScreenQueries'
import { LifelinkLogo } from '@/images/LifeLinkLogo'

import { drawerWidth } from './BaseNavigation'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth})`,
  }),
}))

const settings = [
  { icon: <PersonIcon sx={{ mr: 1 }} />, title: 'Profile' },
  { icon: <LogoutLogo sx={{ mr: 1 }} />, title: 'Logout' },
] as const

interface NavigationBarProps {
  open: boolean
  toggleDrawer: () => void
  signOut?: SignOut
}

const NavigationBar = ({ open, toggleDrawer, signOut }: NavigationBarProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const smallScreen = useSmallScreen()
  const me = useMyUser()
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget)

  const handleCloseUserMenu = (screen?: (typeof settings)[number]) => () => {
    setAnchorElUser(null)
    if (!screen) return

    if (screen.title === 'Logout') {
      signOut?.()
      return navigate('/')
    }

    navigate(`/${screen.title.toLowerCase()}`)
  }

  return (
    <AppBar
      open={open}
      position="fixed"
      sx={{ background: 'rgb(0,78,100)', color: 'white' }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Unless condition={smallScreen}>
          <IconButton aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon sx={{ color: 'common.white' }} />
          </IconButton>
        </Unless>
        <Typography noWrap component="h1" sx={{ ml: -1.7 }} variant="h5">
          <Link
            color="inherit"
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
              fontWeight: 500,
              gap: 2,
            }}
            to="/"
            underline="none"
          >
            <LifelinkLogo />
          </Link>
        </Typography>
        <Box
          sx={{
            flexGrow: 0,
          }}
        >
          <Tooltip aria-labelledby="Open settings" title="Open settings">
            <Box
              role="button"
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
              }}
              onClick={handleOpenUserMenu}
            >
              <Typography id="user-name">{me.data?.name}</Typography>
              <If condition={Boolean(anchorElUser)}>
                <Then>
                  <KeyboardArrowUpIcon />
                </Then>
                <Else>
                  <KeyboardArrowDownIcon />
                </Else>
              </If>
            </Box>
          </Tooltip>
          <Menu
            keepMounted
            anchorEl={anchorElUser}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            id="menu-appbar"
            open={Boolean(anchorElUser)}
            sx={{ mt: '45px' }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            onClose={handleCloseUserMenu()}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting.title}
                onClick={handleCloseUserMenu(setting)}
              >
                {setting.icon}
                <Typography textAlign="center">{setting.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default withAuthenticator(NavigationBar)
