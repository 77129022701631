export const flattenObj = (
  obj: Record<string, unknown>
): Record<string, string | boolean | number> => {
  const result = {}
  for (const i in obj) {
    if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
      //@ts-ignore
      const temp = flattenObj(obj[i])
      for (const j in temp) {
        // @ts-ignore
        result[i + '.' + j] = temp[j]
      }
    } else {
      //@ts-ignore
      result[i] = obj[i]
    }
  }
  return result
}

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest
  describe('the utilities for a flatten object', () => {
    it.each([
      {
        expected: {
          a: 'a',
          'deep.b': 'b',
          'deep.c': 'c',
          'deep.d': 'd',
        },
        input: {
          a: 'a',
          deep: {
            b: 'b',
            c: 'c',
            d: 'd',
          },
        },
      },
    ])('should flatten the object $input', ({ input, expected }) => {
      expect(flattenObj(input)).toStrictEqual(expected)
    })
  })
}
