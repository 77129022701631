import { Box, styled } from '@mui/material'
import { useState } from 'react'

export type StanzaPromptProps = {
  buttonText?: string
  placeholder?: string
}

const Input = styled('input')`
  flex: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;
  border-radius: 0;
  background-color: transparent !important;
  line-height: 32px;
  outline: none;
  font-size: 1em;
  padding-left: 2rem;
  border: none;
`
const Button = styled('button')<{ canSend: boolean }>(({ canSend }) => {
  return {
    background: 'none',
    border: 'none',
    color: canSend ? 'rgb(0, 136, 206)' : 'rgb(162, 162, 162)',
    cursor: canSend ? 'pointer' : 'default',
    font: 'inherit',
    outline: 'inherit',
    padding: '18px 16px 18px 2rem',
  }
})

export function StanzaPrompt(props: StanzaPromptProps) {
  const [value, setValue] = useState('')
  const { placeholder = 'Type a message', buttonText = 'Send' } = props
  return (
    <Box
      sx={{
        borderTopColor: 'rgb(227, 227, 227)',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        display: 'flex',
        minHeight: '60px',
      }}
    >
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button canSend={value.length !== 0}>{buttonText}</Button>
    </Box>
  )
}
