import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { deleteDocument, removeDocument } from '@/graphql/mutations'
import { useMySnackbar } from '@/hooks'
import {
  camelize,
  getDefaultMutations,
  graphQLRequest,
  queryClient,
} from '@/utils'
import {
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  Document,
  RemoveDocumentMutation,
  RemoveDocumentMutationVariables,
} from '@/utils/api'

import { useListDocumentSet } from './useListDocumentSet'

export const useDeleteDocument = (documentSetID: string) => {
  const { orgID } = useParams()
  const snackbars = useMySnackbar({
    errorMessage: 'Failed to delete document',
    infoMessage: 'Deleting document...',
    successMessage: 'Document deleted successfully',
  })
  const queryKey = ['documentSet', documentSetID, 'documents']
  const defaultSanckbars = getDefaultMutations({ queryKey }, snackbars)
  const listDocumentSet = useListDocumentSet(orgID)
  return useMutation({
    mutationFn: async (id: string) => {
      if (!listDocumentSet.data) return
      await graphQLRequest<
        RemoveDocumentMutation,
        RemoveDocumentMutationVariables
      >(removeDocument, {
        documentID: id,
        documentSetSlug: camelize(
          listDocumentSet.data.find((docSet) => docSet.id === documentSetID)
            ?.name ?? ''
        ),
      })
      const res = await graphQLRequest<
        DeleteDocumentMutation,
        DeleteDocumentMutationVariables
      >(deleteDocument, {
        input: {
          id,
        },
      })
      return res.data.deleteDocument
    },
    ...defaultSanckbars,
    onMutate: (id: string) => {
      defaultSanckbars.onMutate()
      queryClient.setQueryData<Document[]>(queryKey, (oldData) =>
        oldData?.filter((doc) => doc.id !== id)
      )
    },
  })
}
