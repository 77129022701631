import { useMutation } from '@tanstack/react-query'

import { useMyUser } from '@/hooks'
import useSnackbar from '@/hooks/useSnackbar'
import { getDefaultMutations, graphQLRequest } from '@/utils'
import {
  CreateCustomComponentInput,
  CreateCustomComponentMutation,
  CreateCustomComponentMutationVariables,
} from '@/utils/api'

import { storeKey } from './CONSTANTS'

interface CustomComponentEnhanced<T extends {}>
  extends Omit<CreateCustomComponentInput, 'settings'> {
  settings: Partial<T>
}

export const useCreateCustomComponent = <T extends {}>(orgID?: string) => {
  const me = useMyUser()
  const snackbars = useSnackbar({
    errorMessage: 'There was an error creating the new custom component',
    infoMessage: 'Creating the new custom component...',
    successMessage: 'The new custom component has been created!',
  })

  return useMutation({
    mutationFn: async (
      data: Omit<
        CustomComponentEnhanced<T>,
        'customComponentUpdatedById' | 'organizationCustomComponentsId'
      >
    ) => {
      if (!orgID) throw new Error('orgID is required')
      const res = await graphQLRequest<
        CreateCustomComponentMutation,
        CreateCustomComponentMutationVariables
      >(
        /* GraphQL */ `
          mutation CreateCustomComponent(
            $input: CreateCustomComponentInput!
            $condition: ModelCustomComponentConditionInput
          ) {
            createCustomComponent(input: $input, condition: $condition) {
              editors
              readers
              admins
              name
              description
              type
              settings
              status
              displayStatus
              visibility
              id
              createdAt
              updatedAt
              organizationCustomComponentsId
              customComponentUpdatedById
              __typename
            }
          }
        `,
        {
          input: {
            ...data,
            customComponentUpdatedById: me.data?.id ?? '',
            organizationCustomComponentsId: orgID,
            settings: JSON.stringify(data.settings) ?? '',
          },
        }
      )
      return res.data?.createCustomComponent
    },
    mutationKey: ['createCustomComponent'],
    ...getDefaultMutations({ queryKey: [storeKey] }, snackbars),
  })
}
