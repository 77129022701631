import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { PromptSetAccordion } from '../components'
import { useListPromptSet } from '../hooks'
import { CreatePromptSetModal } from '../Modals'

export function PromptSet() {
  const { orgID } = useParams()

  const prompts = useListPromptSet(orgID)
  return (
    <Stack>
      {/* <pre>{JSON.stringify(prompts.data, null, 2)}</pre> */}
      {prompts.data?.map((prompt) => (
        <PromptSetAccordion key={prompt.id} {...prompt} />
      ))}
      <CreatePromptSetModal />
    </Stack>
  )
}
