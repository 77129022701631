import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { WithChildren } from '@/utils/types'

interface Options {
  allowClose?: boolean
  cancellationButtonProps?: ButtonProps
  cancellationText?: string
  confirmationButtonProps?: ButtonProps
  confirmationText?: string
  content?: ReactNode
  contentProps?: DialogContentProps
  description?: ReactNode
  dialogProps?: Omit<DialogProps, 'open'>
  title?: ReactNode
  titleProps?: DialogTitleProps
}

const ConfirmContext = createContext<(options?: Options) => Promise<unknown>>(
  () => Promise.resolve()
)

const DEFAULT_OPTIONS: Options = {
  allowClose: true,
  cancellationButtonProps: undefined,
  cancellationText: 'Cancel',
  confirmationButtonProps: undefined,
  confirmationText: 'Ok',
  content: null,
  contentProps: {},
  description: '',
  dialogProps: undefined,
  title: 'Are you sure?',
  titleProps: {},
}

const buildOptions = (defaultOptions: Options, options: Options): Options => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  }
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  }
  const titleProps = {
    ...(defaultOptions.titleProps || DEFAULT_OPTIONS.titleProps),
    ...(options.titleProps || {}),
  }
  const contentProps = {
    ...(defaultOptions.contentProps || DEFAULT_OPTIONS.contentProps),
    ...(options.contentProps || {}),
  }

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    cancellationButtonProps,
    confirmationButtonProps,
    contentProps,
    // @ts-ignore
    dialogProps,
    titleProps,
  }
}

export const ConfirmProvider = ({
  children,
  defaultOptions,
}: WithChildren<{ defaultOptions?: Options }>) => {
  const [options, setOptions] = useState<Options>({
    ...DEFAULT_OPTIONS,
    ...(defaultOptions ?? {}),
  })
  const [resolveReject, setResolveReject] = useState<
    Array<(value?: unknown) => void>
  >([])
  const [resolve, reject] = resolveReject

  const confirm = (options: Options = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(buildOptions(defaultOptions ?? {}, options))
      setResolveReject([resolve, reject])
    })
  }

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
      handleClose()
    }
  }, [resolve, handleClose])
  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <Dialog
        {...options.dialogProps}
        open={resolveReject.length === 2}
        onClose={options.allowClose ? handleClose : undefined}
      >
        {options.title && (
          <DialogTitle {...options.titleProps}>{options.title}</DialogTitle>
        )}
        {options.content ? (
          <DialogContent {...options.contentProps}>
            {options.content}
          </DialogContent>
        ) : (
          options.description && (
            <DialogContent {...options.contentProps}>
              <DialogContentText>{options.description}</DialogContentText>
            </DialogContent>
          )
        )}
        <DialogActions>
          <Button
            {...options.cancellationButtonProps}
            variant="text"
            onClick={handleCancel}
          >
            {options.cancellationText}
          </Button>
          <Button
            color="primary"
            {...options.confirmationButtonProps}
            onClick={handleConfirm}
          >
            {options.confirmationText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const useConfirm = () => {
  const confirm = useContext(ConfirmContext)
  if (!confirm)
    throw new Error('useConfirm must be used within a ConfirmProvider')
  return confirm
}

export default ConfirmProvider
