import { useMutation } from '@tanstack/react-query'

import { createDocument, upsertDocument } from '@/graphql/mutations'
import { useMySnackbar, useMyUser } from '@/hooks'
import {
  camelize,
  getDefaultMutations,
  graphQLRequest,
  SomeRequired,
} from '@/utils'
import {
  CreateDocumentInput,
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  ProcessingStatus,
  UpsertDocumentMutation,
  UpsertDocumentMutationVariables,
} from '@/utils/api'

export const useCreateDocument = (orgID?: string) => {
  const me = useMyUser()
  const snackbars = useMySnackbar({
    errorMessage: 'Error creating document',
    infoMessage: 'Creating document...',
    successMessage: 'Document created successfully',
  })
  return useMutation({
    mutationFn: async (form: SomeRequired<CreateDocumentInput, 'name'>) => {
      if (!form.namespaceDocumentsId)
        throw new Error('No namespace ID provided')

      const res = await graphQLRequest<
        CreateDocumentMutation,
        CreateDocumentMutationVariables
      >(createDocument, {
        input: {
          ...form,
          active: true,
          documentUpdatedById: me.data?.id as string,
          organizationDocumentsId: orgID as string,
          status: ProcessingStatus.init,
        },
      })
      const documentID = res.data?.createDocument?.id
      if (!documentID) throw new Error('No document ID returned')
      if (
        !!res.data.createDocument?.id &&
        !!res.data.createDocument?.namespace?.name
      )
        await graphQLRequest<
          UpsertDocumentMutation,
          UpsertDocumentMutationVariables
        >(upsertDocument, {
          documentID: res.data.createDocument?.id,
          documentSetSlug: camelize(res.data.createDocument?.namespace?.name),
        })
      return res.data?.createDocument
    },
    ...getDefaultMutations({ queryKey: ['documentSet'] }, snackbars),
  })
}
