import { Drawer, Toolbar, Typography } from '@mui/material'
import { Else, If, Then } from 'react-if'

import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useSmallScreen } from '@/hooks/useScreenQueries'

import DrawerMenu from './DrawerMenu'
import NavigationBar from './NavigationBar'
import PermanentDrawer from './PermanentDrawer'
export const drawerWidth = '18rem'

export function BaseNavigation() {
  const [open, setOpen] = useLocalStorage('dashboard-open', true)
  const toggleDrawer = () => setOpen(!open)
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <NavigationBar open={open && isSmallScreen} toggleDrawer={toggleDrawer} />
      <If condition={isSmallScreen}>
        <Then>
          <PermanentDrawer open={open} toggleDrawer={toggleDrawer} />
        </Then>
        <Else>
          <Drawer
            anchor="left"
            open={open}
            variant="temporary"
            onClose={toggleDrawer}
          >
            <Toolbar>
              <Typography noWrap variant="h6">
                Builder
              </Typography>
            </Toolbar>
            <DrawerMenu open={open} toggleDrawer={toggleDrawer} />
          </Drawer>
        </Else>
      </If>
    </>
  )
}
