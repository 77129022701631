import { Button, capitalize, IconButton, Stack } from '@mui/material'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { When } from 'react-if'

import {
  Icon,
  minLength,
  required,
  VailSelect,
  VailTextField,
} from '@/components'
import {
  ChatPromptRole,
  CreatePromptSetInput,
  PromptSetTypes,
} from '@/utils/api'

export const PromptSetForm = ({
  form,
}: {
  form: UseFormReturn<CreatePromptSetInput, any, undefined>
}) => {
  const chatArr = useFieldArray({
    control: form.control,
    name: 'chat',
  })
  return (
    <Stack gap={2} p={2}>
      <VailTextField
        control={form.control}
        label="Name"
        name="name"
        rules={{
          minLength: minLength(3),
          required: required,
        }}
      />
      <VailSelect
        control={form.control}
        label="Prompt Type"
        name="type"
        options={[
          {
            label: 'Chat Template',
            value: PromptSetTypes.chatTemplate,
          },
          {
            label: 'Prompt Template',
            value: PromptSetTypes.promptTemplate,
          },
        ]}
        rules={{ required }}
      />
      <VailTextField
        multiline
        control={form.control}
        label={
          form.watch('type') === PromptSetTypes.promptTemplate
            ? 'Prompt'
            : 'System'
        }
        minRows={5}
        name="prompt"
        rules={{
          validate: {
            hasValidVariables: (value) => {
              if (typeof value === 'string')
                return (
                  !value.match(/\{[^}]*\s[^}]*\}/gm) ||
                  'The variables need to be camelCase'
                )
              return true
            },
          },
        }}
      />
      <When condition={form.watch('type') === PromptSetTypes.chatTemplate}>
        {chatArr.fields.map((field, idx) => (
          <Stack key={field.id} direction="row" gap={2}>
            <VailSelect
              control={form.control}
              defaultValue={[]}
              label="Response"
              name={`chat.${idx}.role`}
              options={Object.values(ChatPromptRole).map((role) => ({
                label: capitalize(role),
                value: role,
              }))}
              rules={{
                validate: {
                  required: (value) => !!value || 'You need to add a role',
                },
              }}
              sx={{ flexBasis: 200 }}
            />
            <VailTextField
              control={form.control}
              label="Text"
              name={`chat.${idx}.response`}
            />
            <IconButton
              size="small"
              sx={{ height: 'fit-content' }}
              onClick={() => chatArr.remove(idx)}
            >
              <Icon name="delete" />
            </IconButton>
          </Stack>
        ))}
        <Button
          sx={{ alignSelf: 'flex-start' }}
          onClick={() =>
            chatArr.append({ response: '', role: ChatPromptRole.assistant })
          }
        >
          Add Message
        </Button>
      </When>
    </Stack>
  )
}
