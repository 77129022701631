import { mergeDeepRight } from 'ramda'
import { useEffect, useRef } from 'react'

export interface UseTitleOptions {
  restoreOnUnmount?: boolean
}

const DEFAULT_OPTIONS = {
  defaultTitle: 'Vail',
  restoreOnUnmount: false,
}
export const useDocTitle = (title: string, options?: UseTitleOptions) => {
  const actualOptions = mergeDeepRight(DEFAULT_OPTIONS, options ?? {})
  const prevTitle = useRef(document.title)
  if (document.title !== title) document.title = `Vail | ${title}`

  useEffect(() => {
    if (actualOptions?.restoreOnUnmount) {
      const actual = prevTitle.current
      return () => {
        document.title = `Vail | ${actual}`
      }
    } else return
  }, [actualOptions?.restoreOnUnmount])
}
export default useDocTitle
