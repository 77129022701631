import { capitalize, Stack } from '@mui/material'
import { Fragment } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'

import { DrawerItem } from '@/components/BaseNavigation/DrawerItem'
import { useListSolution } from '@/screens/Solutions/hooks'

type SolutionsSubsectionProps = {
  open: boolean
  tooltipOpen: string | null
  onClick: (path: string) => () => void
  onClose: () => void
  onOpen: (title: string) => () => void
}

export const SolutionsSubsection = (props: SolutionsSubsectionProps) => {
  const { orgID } = useParams()
  const solutions = useListSolution(orgID)
  const { pathname } = useLocation()

  if (!props.open) return null
  const isSubsectionOpen = (solutionID: string) =>
    pathname.startsWith(`/${orgID}/solutions/${solutionID}`)
  return (
    <Stack>
      {solutions.data?.slice(0, 5)?.map(({ id, name }) => (
        <Fragment key={id}>
          <DrawerItem
            {...props}
            enabled
            iconName={isSubsectionOpen(id) ? 'chevron-down' : 'chevron-right'}
            path={`/${orgID}/solutions/${id}`}
            sx={{ pl: 4.5, px: 0 }}
            title={name}
            onOpen={props.onOpen(name)}
          />
          <Routes>
            <Route
              element={
                <>
                  {['settings', 'conversation', 'version'].map((tab) => (
                    <DrawerItem
                      key={tab}
                      {...props}
                      enabled
                      path={`/${orgID}/solutions/${id}?tab=${tab}`}
                      sx={{ pl: 8, px: 0 }}
                      title={capitalize(tab)}
                      onOpen={props.onOpen(name)}
                    />
                  ))}
                </>
              }
              path={`${id}/*`}
            />
          </Routes>
        </Fragment>
      ))}
    </Stack>
  )
}
