import { Navigate } from 'react-router-dom'

import { useIsAdmin } from '@/hooks/useIsAdmin'
import { WithChildren } from '@/utils/types'

export interface AdminOnlyProps {
  isRoute?: boolean
}
/**
 * This component will render its children only if you are a user with admin
 * level privileges. In case the user is not an Admin is going to return you
 * to a 404
 * @param children
 * @param { boolean} isRoute
 * @constructor
 */
export const AdminOnly = ({
  children,
  isRoute = false,
}: WithChildren<AdminOnlyProps>) => {
  const isAdminQuery = useIsAdmin()
  if (isAdminQuery.status !== 'success') return null
  if (isAdminQuery.data === true) return <>{children}</>
  if (isRoute) return <Navigate replace to="/error/404" />
  return null
}
