import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Icon, VailTextField } from '@/components'
import { CreateNamespaceInput } from '@/utils/api'

import { useCreateDocumentSet } from '../hooks'

export type CreateNamespaceModalProps = {
  orgId?: string
}
export function CreateDocumentSet({ orgId }: CreateNamespaceModalProps) {
  const [open, setOpen] = useState(false)
  const form = useForm<CreateNamespaceInput>()
  const createDocumentSet = useCreateDocumentSet(orgId)
  const handleClose = () => setOpen(false)
  const handleOpen = () => {
    form.reset({ name: '' })
    setOpen(true)
  }

  const handleSubmit = form.handleSubmit(async (data) => {
    await createDocumentSet.mutateAsync(data)
    handleClose()
  })

  if (!orgId) return null
  return (
    <>
      <Button
        id="add_document_set_button"
        startIcon={<Icon name="add" />}
        sx={{ alignSelf: 'flex-end', my: 3 }}
        variant="contained"
        onClick={handleOpen}
      >
        Add Document set
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="new-namespace-title">New Document set</DialogTitle>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <DialogContent
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <DialogContentText id="new-namespace-description">
                Create a new Document set for uploading sources related to a
                single topic
              </DialogContentText>
              <VailTextField label="Name" name="name" />
            </DialogContent>
            <DialogActions>
              <Button type="reset" variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button id="create_document_set" type="submit">
                Create
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  )
}
