import { StorageManager } from '@aws-amplify/ui-react-storage'
import { ProcessFile } from '@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { getUrl } from 'aws-amplify/storage'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { prop } from 'ramda'
import { useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { When } from 'react-if'
import { useParams } from 'react-router-dom'

import { Icon, VailTextField } from '@/components'
import { useAppsyncRefetch, useSwitch } from '@/hooks'
import { safeCypressID, validUrlRule } from '@/utils'
import { CreateDocumentInput, DocTypes } from '@/utils/api'

import { useCreateDocument } from '../hooks'

const MotionStack = motion(Stack)
const MotionButton = motion(Button)

export type CreateDocumentModalProps = {
  documentSetId: string
}
export const CreateDocumentModal = ({
  documentSetId,
}: CreateDocumentModalProps) => {
  const { orgID } = useParams()
  const createDoc = useCreateDocument(orgID)
  const [open, flipOpen] = useSwitch(false)
  const docName = useRef('')
  const [docType, setDocType] = useState<'document' | 'webpage' | ''>('')
  const setRefetch = useAppsyncRefetch((state) => prop('setRefetch', state))
  const form = useForm<CreateDocumentInput>()

  const onOpen = () => {
    setDocType('')
    flipOpen()
    form.reset({ name: '', source: '' })
  }
  const buttonVariants: Variants = {
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    initial: { opacity: 0 },
  }
  const stackVariants: Variants = {
    animate: { opacity: 1, translateX: 0 },
    exit: { opacity: 0, translateX: 100 },
    initial: { opacity: 0, translateX: -100 },
  }

  const onSubmit = form.handleSubmit((data: CreateDocumentInput) => {
    createDoc.mutate({
      name: data.name,
      namespaceDocumentsId: documentSetId,
      source: data.source,
      type: DocTypes.webpage,
    })
    setRefetch(documentSetId, true)
    flipOpen()
  })
  const processFile: ProcessFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop()
    docName.current = file.name
    return file
      .arrayBuffer()
      .then((buffer) => window.crypto.subtle.digest('SHA-1', buffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer))
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .slice(0, 8)
          .join('')
        const key = `/${hashHex}.${fileExtension}`

        return { file, key }
      })
  }
  return (
    <>
      <Button
        id={safeCypressID('create document button')}
        startIcon={<Icon name="add" />}
        sx={{ alignSelf: 'flex-end' }}
        onClick={onOpen}
      >
        Create Document
      </Button>
      <FormProvider {...form}>
        <Dialog
          fullWidth
          component="form"
          maxWidth="xs"
          open={open}
          sx={{ overflow: 'hidden' }}
          onClose={flipOpen}
          onSubmit={onSubmit}
        >
          <DialogTitle id={safeCypressID('modal title')}>
            Create Document
          </DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            <AnimatePresence mode="popLayout">
              {docType === '' ? (
                <MotionStack
                  key={docType}
                  animate="animate"
                  direction="row"
                  exit="initial"
                  gap={2}
                  initial={false}
                  overflow="hidden"
                  p={2}
                  variants={stackVariants}
                >
                  <MotionButton
                    fullWidth
                    id={safeCypressID('set document type')}
                    variants={buttonVariants}
                    onClick={() => setDocType('document')}
                  >
                    <Stack alignItems="center">
                      <Icon name="document" />
                      <Typography>Document (PDF or word)</Typography>
                    </Stack>
                  </MotionButton>
                  <MotionButton
                    fullWidth
                    id={safeCypressID('set webpage button')}
                    onClick={() => setDocType('webpage')}
                  >
                    <Stack alignItems="center">
                      <Icon name="link" />
                      <Typography>Webpage</Typography>
                    </Stack>
                  </MotionButton>
                </MotionStack>
              ) : null}
              {docType === 'webpage' ? (
                <MotionStack
                  key={docType}
                  animate="animate"
                  exit="initial"
                  gap={2}
                  initial="initial"
                  overflow="hidden"
                  p={2}
                  variants={stackVariants}
                >
                  <VailTextField label="Name" name="name" />
                  <VailTextField
                    label="Webpage"
                    name="source"
                    rules={{
                      validate: { validUrlRule },
                    }}
                  />
                </MotionStack>
              ) : null}
              {docType === 'document' ? (
                <MotionStack
                  key={docType}
                  animate="animate"
                  exit="initial"
                  initial="initial"
                  overflow="hidden"
                  p={2}
                  variants={stackVariants}
                >
                  <StorageManager
                    acceptedFileTypes={['.docx', '.pdf']}
                    accessLevel="guest"
                    displayText={{
                      browseFilesText: `Look for your Document`,
                      dropFilesText: `Drag and drop your pdf or docx here`,
                    }}
                    maxFileCount={1}
                    path={`${orgID}/documents/${documentSetId}`}
                    processFile={processFile}
                    onUploadSuccess={async ({ key }) => {
                      if (!key) return
                      const result = await getUrl({
                        key,
                        options: {
                          accessLevel: 'guest',
                          expiresIn: 900,
                        },
                      })

                      await createDoc.mutateAsync({
                        name: docName.current ?? 'Document Name',
                        namespaceDocumentsId: documentSetId,
                        source: decodeURIComponent(
                          result.url.pathname.replace('%3A', ':')
                        ),
                        type: DocTypes.pdf,
                      })
                      setRefetch(documentSetId, true)
                      flipOpen()
                    }}
                  />
                </MotionStack>
              ) : null}
            </AnimatePresence>
          </DialogContent>
          <DialogActions>
            <Button
              id={safeCypressID('cancel creation button')}
              variant="text"
              onClick={() => {
                if (docType === '') flipOpen()
                else setDocType('')
              }}
            >
              Cancel
            </Button>
            <When condition={docType === 'webpage'}>
              <Button id={safeCypressID('submit webpage button')} type="submit">
                Create
              </Button>
            </When>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  )
}
