import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { deleteNamespace, removeNamespace } from '@/graphql/mutations'
import { useMySnackbar } from '@/hooks'
import {
  camelize,
  getDefaultMutations,
  graphQLRequest,
  queryClient,
} from '@/utils'
import {
  DeleteNamespaceMutation,
  DeleteNamespaceMutationVariables,
  Namespace,
  RemoveNamespaceMutation,
  RemoveNamespaceMutationVariables,
} from '@/utils/api'

import { useListDocumentSet } from './useListDocumentSet'

export const useDeleteDocumentSet = (documentSetID: string) => {
  const { orgID } = useParams()
  const snackbars = useMySnackbar({
    errorMessage: 'Failed to delete document set',
    infoMessage: 'Deleting document set...',
    successMessage: 'Document set deleted successfully',
  })
  const queryKey = ['documentSet', orgID]
  const defaultSanckbars = getDefaultMutations({ queryKey }, snackbars)
  const listDocumentSet = useListDocumentSet(orgID)
  return useMutation({
    mutationFn: async (id: string) => {
      if (!listDocumentSet.data) return

      const res = await graphQLRequest<
        DeleteNamespaceMutation,
        DeleteNamespaceMutationVariables
      >(deleteNamespace, {
        input: {
          id,
        },
      })
      await graphQLRequest<
        RemoveNamespaceMutation,
        RemoveNamespaceMutationVariables
      >(removeNamespace, {
        documentSetSlug: camelize(res.data.deleteNamespace?.name ?? ''),
        namespaceID: id,
      })
      return res.data.deleteNamespace
    },
    ...defaultSanckbars,
    onMutate: (id: string) => {
      defaultSanckbars.onMutate()
      queryClient.setQueryData<Namespace[]>(queryKey, (oldData) =>
        oldData?.filter((doc) => doc.id !== id)
      )
    },
  })
}
