export const safeCypressID = (id: string) =>
  id.toLowerCase().replace(/\W/g, '_').replace(/ /g, '_')

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest

  describe('safeCypressID question', () => {
    it.each([
      { expected: 'bla', id: 'bla' },
      { expected: 'this_is_a_form_id1', id: 'this.is.a.form.id1' },
      { expected: 'this_is_a_form_id2', id: 'this is a form id2' },
      { expected: 'this_is_a_form_id3', id: 'ThIs-Is_A_Form_ID3' },
      {
        expected: 'cy_ondricka__vonrueden_and_barrows_button',
        id: 'CY-Ondricka,-VonRueden-and-Barrows_button',
      },
    ])('safeCypressID($id) -> $expected', ({ id, expected }) => {
      expect(safeCypressID(id)).toBe(expected)
    })
  })
}
