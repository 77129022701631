import { ComponentType } from '@/utils/api'

/**
 * Parses the given parameter and checks if it matches the specified component type.
 *
 * @template T - The type of the parameter object.
 * @param {T | string} param - The parameter to parse.
 * @param {ComponentType} componentType - The component type to match against.
 * @returns {T | null} - The parsed parameter if it matches the component type, otherwise null.
 */
export function parseComponentTypes<T extends { settings: object }>(
  param: T | string,
  componentType: ComponentType
): T | null {
  let data: T | null = null
  try {
    const parsedParam = typeof param === 'string' ? JSON.parse(param) : param
    if (parsedParam?.type?.toLowerCase() === componentType.toLowerCase())
      data = parsedParam
  } catch (e) {
    data = null
  }
  return data
}
