import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { getUrl } from 'aws-amplify/storage'
import { join, pipe, slice, split } from 'ramda'
import { Case, Else, If, Switch, Then, When } from 'react-if'

import { Icon, useConfirm, VailAccordion } from '@/components'
import { upsertDocument } from '@/graphql/mutations'
import { camelize, formatDate, graphQLRequest, safeCypressID } from '@/utils'
import {
  DocTypes,
  Document,
  Namespace,
  ProcessingStatus,
  UpsertDocumentMutation,
  UpsertDocumentMutationVariables,
} from '@/utils/api'

import {
  useAccordionStore,
  useDeleteDocument,
  useDeleteDocumentSet,
  useListDocuments,
  useUpdateDocument,
} from '../hooks'
import { CreateDocumentModal } from '../Modals'

const getErrorSubtitle = (data?: Document[]) => {
  if (data?.length === 0) return 'There are no documents.'
  const error = data?.find((i) => i.status === ProcessingStatus.error)

  if (!error) return ''

  return `Document ${error.name} failed to upsert. Reason: ${error.displayStatus}`
}

const parseKey = pipe(split('/'), slice(2, Infinity), join('/'))

export const DocumentSetAccordion = (props: Namespace) => {
  const { id, name } = props

  const deleteDocument = useDeleteDocument(id)
  const updateDocument = useUpdateDocument(id)
  const deleteDocumentSet = useDeleteDocumentSet(id)
  const documents = useListDocuments(id, props.organizationNamespacesId)
  const state = useAccordionStore((s) => s.opened)
  const toggle = useAccordionStore((s) => s.toggle)
  const confirm = useConfirm()

  const reUpsertDocument = async (
    documentID: string,
    documentSetSlug: string
  ) => {
    await graphQLRequest<
      UpsertDocumentMutation,
      UpsertDocumentMutationVariables
    >(upsertDocument, {
      documentID,
      documentSetSlug,
    })
    await updateDocument.mutateAsync({
      id: documentID,
      status: ProcessingStatus.init,
    })
  }
  const deleteDocumentHandle = async (id: string) => {
    try {
      await confirm({
        cancellationText: 'Cancel',
        confirmationText: 'Delete',
        description: 'This action is not reversible',
        title: 'Are you sure you want to delete this document?',
      })
      deleteDocument.mutate(id)
    } catch (e) {
      console.log(e)
    }
  }
  const deleteSetHandle = async (id: string) => {
    try {
      if (documents.data?.length !== 0)
        await confirm({
          cancellationText: 'Ok',
          confirmationButtonProps: {
            sx: { display: 'none' },
          },
          description: 'Please delete all documents before deleting the set',
          title: 'Unable to delete document set',
        })
      await confirm({
        title: 'Are you sure you want to delete this document set?',
      })
      deleteDocumentSet.mutate(id)
    } catch (e) {
      console.log(e)
    }
  }

  const viewDocument = (source?: string | null) => async () => {
    if (!source) return
    const { url } = await getUrl({
      key: parseKey(source),
      options: {
        accessLevel: 'guest',
      },
    })
    window.open(url, '_blank')
  }

  const errSubtitle = getErrorSubtitle(documents.data)
  return (
    <VailAccordion
      endContent={
        !!errSubtitle && (
          <Alert
            severity={
              errSubtitle === 'There are no documents.' ? 'warning' : 'error'
            }
            sx={{ flex: 1 }}
          >
            {errSubtitle}
          </Alert>
        )
      }
      expanded={!!state?.[id]}
      id={safeCypressID(id)}
      subTitle={`slug: ${camelize(name)}`}
      sx={{
        '& .MuiAccordionSummary-content>.MuiStack-root': {
          flex: 1,
        },
      }}
      title={name}
      onChange={() => toggle(id)}
    >
      <If condition={documents.data?.length !== 0}>
        <Then>
          {documents.data?.map((document) => {
            if (!document) return null
            const isProcessing = !!(
              document.status && document.status === ProcessingStatus.processing
            )

            return (
              <Stack
                key={document?.id}
                direction="row"
                sx={
                  isProcessing
                    ? {
                        '&:hover': {
                          bgcolor: (s) =>
                            s.palette.mode === 'dark'
                              ? s.palette.grey[800]
                              : s.palette.grey[100],
                        },
                        cursor: 'progress',
                        opacity: 0.7,
                        transition: 'background-color 0.3s ease-in-out',
                      }
                    : {
                        '&:hover': {
                          bgcolor: (s) =>
                            s.palette.mode === 'dark'
                              ? s.palette.grey[900]
                              : s.palette.grey[100],
                        },
                        transition: 'background-color 0.3s ease-in-out',
                      }
                }
              >
                <Stack flex={1}>
                  <Typography id={safeCypressID(document.name)}>
                    {document.name}
                  </Typography>
                  <Typography variant="caption">
                    {formatDate(document?.createdAt)}
                  </Typography>
                  <Typography variant="caption">
                    {document?.updatedBy?.name}
                  </Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row">
                  <When condition={document.status === ProcessingStatus.error}>
                    <Button
                      variant="text"
                      onClick={() =>
                        reUpsertDocument(document.id, camelize(props.name))
                      }
                    >
                      Upsert Document
                    </Button>
                  </When>
                  <Switch>
                    <Case condition={isProcessing}>
                      <Typography sx={{ maxWidth: '50%', ml: 'auto' }}>
                        {document.displayStatus ?? 'processing document'}
                      </Typography>
                    </Case>
                    <Case condition={document.type === DocTypes.webpage}>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => deleteDocumentHandle(document.id)}
                      >
                        Delete webpage
                      </Button>
                      <Button
                        href={document.source ?? ''}
                        LinkComponent="a"
                        target="_blank"
                        variant="text"
                      >
                        View Webpage
                      </Button>
                    </Case>
                    <Case condition={document.type === DocTypes.pdf}>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => deleteDocumentHandle(document.id)}
                      >
                        Delete Document
                      </Button>
                      <Button
                        variant="text"
                        onClick={viewDocument(document?.source)}
                      >
                        View Document
                      </Button>
                    </Case>
                  </Switch>
                </Stack>
              </Stack>
            )
          })}
        </Then>
        <Else>
          <div>No Documents</div>
        </Else>
      </If>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Button
          color="error"
          startIcon={<Icon name="delete" />}
          variant="text"
          onClick={() => deleteSetHandle(id)}
        >
          Delete Document Set
        </Button>

        <CreateDocumentModal documentSetId={id} />
      </Box>
    </VailAccordion>
  )
}
