import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const formatDate = (dateStr: string) => {
  const date = dayjs(dateStr)

  return date.diff(dayjs(), 'day') < 0
    ? date.format('MMMM D h:mm A')
    : date.fromNow()
}

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest
  describe('formatDate', () => {
    it.each([
      {
        date: dayjs().subtract(1, 'hour').toISOString(),
        expected: 'an hour ago',
      },
      {
        date: dayjs().subtract(1, 'minute').toISOString(),
        expected: 'a minute ago',
      },
      {
        date: dayjs('February 10 7:23 AM').toISOString(),
        expected: 'February 10 7:23 AM',
      },
      {
        date: dayjs().add(1, 'hour').toISOString(),
        expected: 'in an hour',
      },
      {
        date: dayjs().subtract(15, 'minutes').toISOString(),
        expected: '15 minutes ago',
      },
    ])('format date($date)=>$expected', ({ expected, date }) => {
      const formattedDate = formatDate(date)
      expect(formattedDate).toBe(expected)
    })
  })
}
