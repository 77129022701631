import { Navigate, Route, Routes } from 'react-router-dom'

import { EditLLM } from './EditLLM'

const LLM = () => (
  <Routes>
    <Route element={<EditLLM />} path="/*" />
    <Route element={<Navigate replace to="/error/404" />} path="*" />
  </Routes>
)

export default LLM
