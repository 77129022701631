import { Button, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { VailTextField } from '@/components'
import { useCreateOrg } from '@/hooks'
import { camelize, required, validUrlRule } from '@/utils'
import { CreateOrganizationInput } from '@/utils/api'

export const CreateOrg = () => {
  const create = useCreateOrg()
  const form = useForm<CreateOrganizationInput>()
  const navigate = useNavigate()

  const onSubmit = form.handleSubmit(async (data) => {
    const slug = camelize(data.slug)
    const res = await create.mutateAsync({
      ...data,
      admins: `${slug}-admins`,
      editors: `${slug}-editors`,
      id: slug,
      readers: `${slug}-readers`,
      slug,
      tags: [],
    })
    if (res?.id) navigate(`/${res.id}/organization`)
  })

  return (
    <FormProvider {...form}>
      <div>
        <Typography variant="h3">Create Organization</Typography>
        <Stack
          aria-label="create-org-form"
          className="mx-auto max-w-sm"
          component="form"
          sx={{ direction: { md: 'row', xs: 'column' }, gap: 2, pt: 2 }}
          onSubmit={onSubmit}
        >
          <VailTextField control={form.control} label="Name" name="name" />
          <VailTextField control={form.control} label="Slug" name="slug" />
          <VailTextField
            defaultValue=""
            label="Privacy Policy URL"
            name="privacyPolicy"
            rules={{
              ...required,
              validate: { validUrlRule },
            }}
          />
          <VailTextField
            defaultValue=""
            label="Terms of Use URL"
            name="termsOfUse"
            rules={{
              ...required,
              validate: { validUrlRule },
            }}
          />
          <Button type="submit">Create</Button>
        </Stack>
      </div>
    </FormProvider>
  )
}
