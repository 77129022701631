import { capitalize, MenuItem, TextField, TextFieldProps } from '@mui/material'
import { assocPath, equals, findIndex } from 'ramda'
import {
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'

import { getError } from '@/components/reactHookForm/utils'
import { PartialBy, safeCypressID } from '@/utils'

export type Option =
  | { value: string; label: string; disabled?: boolean }
  | string

export type VailSelectProps<T extends object> = PartialBy<
  UseControllerProps<T>,
  'control'
> &
  TextFieldProps & {
    options?: Option[]
    withNone?: boolean
    noneLabel?: string
  }

export const VailSelect = <T extends {}>(props: VailSelectProps<T>) => {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    disabled,
    control,
    withNone,
    noneLabel = 'None',
    ...textFieldProps
  } = props
  const form = useFormContext<T>()
  if (!form) throw new Error('VailSelect must be used inside a FormProvider')
  if (!form.control && !control)
    throw new Error(
      'VailSelect must be used inside a FormProvider or pass the control prop'
    )
  const { formState } = form
  const errorName = getError(formState, props.name)

  const getValue = (value: string | string[]) => {
    const multiple = props.SelectProps?.multiple
    if (multiple && !Array.isArray(value)) return [value]
    if (!multiple && Array.isArray(value)) return value[0]
    return value
  }
  const { field } = useController({
    control: control || form.control,
    defaultValue,
    disabled,
    name,
    rules,
    shouldUnregister,
  })
  const { value, onChange, ...restField } = field
  const v = getValue(value ?? (props.SelectProps?.multiple ? [] : ''))
  if (!props.options)
    return (
      <TextField
        disabled
        fullWidth
        select
        label={props.label}
        value="Loading..."
      >
        <MenuItem id={safeCypressID(`option str loading`)} value="Loading...">
          {capitalize('Loading...')}
        </MenuItem>
      </TextField>
    )
  return (
    <TextField
      fullWidth
      select
      error={!!errorName}
      helperText={errorName ?? props.helperText}
      id={safeCypressID(props.id ?? name)}
      inputRef={restField.ref}
      label={props.label}
      value={v ?? props.defaultValue}
      onChange={(e) => {
        const multiple = props.SelectProps?.multiple
        if (multiple && withNone) {
          const newEvent = assocPath(
            ['target', 'value'],
            multipleSelectWithNone(e.target.value),
            e
          )
          props?.onChange?.(newEvent)
          onChange(newEvent)
        } else {
          props?.onChange?.(e)
          onChange(e)
        }
      }}
      {...restField}
      {...textFieldProps}
    >
      {props.options?.map((option) =>
        typeof option === 'string' ? (
          <MenuItem
            key={option}
            id={safeCypressID(`option str ${option}`)}
            value={option}
          >
            {capitalize(option)}
          </MenuItem>
        ) : (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            id={safeCypressID(`option ${option.label}`)}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        )
      )}
      {!!withNone && (
        <MenuItem id={safeCypressID('option none')} value="">
          {noneLabel}
        </MenuItem>
      )}
    </TextField>
  )
}

export function multipleSelectWithNone(value: string[] | string) {
  if (!Array.isArray(value)) return value.split(',').filter(Boolean)
  return findIndex(equals(''), value) === value.length - 1
    ? ['']
    : value.filter(Boolean)
}
