import '@aws-amplify/ui-react/styles.css'

import { withAuthenticator } from '@aws-amplify/ui-react'
import { Box, Container, Toolbar } from '@mui/material'
import { Hub } from 'aws-amplify/utils'
import { SnackbarProvider } from 'notistack'
import { Route, Routes } from 'react-router-dom'

import {
  AdminOnly,
  BaseNavigation,
  ConfirmProvider,
  GlobalFetchingIndicator,
  GoHome,
  OrgIdOutlet,
} from '@/components'
import EditOrganization from '@/screens/Organizations/EditOrganization'
import ListOrganizations from '@/screens/Organizations/ListOrganizations'
import queryClient from '@/utils/queryClient'

import styles from './App.module.css'
import DefaultErrorBoundary from './components/ErrorBoundary'
import {
  ComponentLibrary,
  CreateOrg,
  EngagementGoals,
  Error404,
  Error500,
  LLM,
  Profile,
  Solutions,
  Templates,
  UserManagement,
} from './screens'
import AdminManagement from './screens/AdminManagement/AdminManagement'

Hub.listen('auth', (data) => {
  const { event } = data.payload
  switch (event) {
    case 'signInWithRedirect':
    case 'signedIn':
    case 'signedOut':
      queryClient.invalidateQueries().catch((err) => {
        console.error(err)
      })
  }
})

function App() {
  return (
    <Box sx={{ display: 'flex' }}>
      <BaseNavigation />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          mb: 0,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={3000}
          classes={{ containerAnchorOriginTopCenter: styles.notification }}
          maxSnack={3}
        >
          <Container maxWidth="xl" sx={{ height: 'calc(100vh - 64px)', mb: 0 }}>
            <DefaultErrorBoundary>
              <Routes>
                <Route element={<Error404 />} path="error/404" />
                <Route element={<Profile />} path="profile" />
                {import.meta.env.DEV && (
                  <Route element={<AdminManagement />} path="admin" />
                )}
                <Route element={<Error500 />} path="error/500" />
                <Route
                  element={
                    <AdminOnly isRoute>
                      <UserManagement />
                    </AdminOnly>
                  }
                  path="users"
                />

                <Route
                  element={
                    <ConfirmProvider>
                      <ListOrganizations />
                    </ConfirmProvider>
                  }
                  path="/"
                />
                <Route
                  element={
                    <ConfirmProvider>
                      <EditOrganization />
                    </ConfirmProvider>
                  }
                  path="/new/organization"
                />
                <Route element={<Error404 />} path="error/404" />
                <Route element={<Profile />} path="profile" />
                <Route element={<Error500 />} path="error/500" />
                <Route element={<Templates />} path="templates/*" />
                <Route element={<CreateOrg />} path="new-org" />
                <Route element={<OrgIdOutlet />} path="/:orgID">
                  <Route element={<LLM />} path="llm/*" />
                  <Route
                    element={<ComponentLibrary />}
                    path="component-library/*"
                  />
                  <Route element={<Solutions />} path="solutions/*" />
                  <Route
                    element={<EngagementGoals />}
                    path="engagement-goals/*"
                  />
                  <Route
                    element={
                      <ConfirmProvider>
                        <EditOrganization />
                      </ConfirmProvider>
                    }
                    path="organization"
                  />
                  <Route element={<GoHome />} path="*" />
                </Route>
              </Routes>
            </DefaultErrorBoundary>
          </Container>
        </SnackbarProvider>
      </Box>
      <GlobalFetchingIndicator />
    </Box>
  )
}

export default withAuthenticator(App, {
  hideSignUp: import.meta.env.MODE !== 'development',
})
