import { trim, uniq } from 'ramda'

/**
 * This is a function that returns a function that splits a string by a delimiter and trims the resulting strings. It also removes any empty strings.
 * @param {string } delimiter - The delimiter to split the string by.
 * @example
 * const splitByCommaAndTrim = splitAndTrim(',')
 * splitByCommaAndTrim('a, b, c') // ['a', 'b', 'c']
 *
 * @example
 * const splitBySpaceAndTrim = splitAndTrim(' ')
 * splitBySpaceAndTrim('a b c') // ['a', 'b', 'c']
 */
export const splitAndTrim =
  (delimiter: string = ',') =>
  (str?: string | null) =>
    uniq((str ?? '').split(delimiter).map(trim)).filter(Boolean)

export default splitAndTrim

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest
  it.each([
    [',', 'a,b,c', ['a', 'b', 'c']],
    ['.', 'a.b.c', ['a', 'b', 'c']],
  ])('splitAndTrim(%s) -> %o', (delimiter, val, expected) => {
    const splitter = splitAndTrim(delimiter)
    expect(splitter(val)).toStrictEqual(expected)
  })
}
