import { GraphQLQuery } from '@aws-amplify/api'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import * as Sentry from '@sentry/react'
import {
  filter,
  is,
  isEmpty,
  isNil,
  map,
  not,
  omit,
  pipe,
  prop,
  unless,
  values,
  when,
} from 'ramda'

import { AmplifyGraphQLclient } from './amplify'

export const cleanTypename = <T extends {}>(o: T): T =>
  //@ts-ignore
  pipe(
    unless(is(Array), omit(['__typename'])),
    map(when(is(Object), cleanTypename))
  )(o)

//@ts-ignore
const hasErrors = pipe(prop('data'), values, filter(isNil), isEmpty, not)

const getQueryName = (query: string) => {
  const regex = /(query|mutation) (\w+)/
  const matches = query.match(regex)
  if (!matches) return null
  return matches[2]
}

/**
 * This function will make a request to our vail api
 * @param {string} query - The query/mutation that we are going to use
 * @param {Object} variables - The variables of the query
 */
export async function graphQLRequest<Query, Variables extends object>(
  query: string,
  variables?: Variables
) {
  type QueryType = GraphQLQuery<Query>
  try {
    return (await AmplifyGraphQLclient.graphql({
      query,
      //@ts-ignore
      variables: cleanTypename(variables ?? {}),
    })) as GraphQLResult<QueryType>
  } catch (e: any) {
    if (hasErrors(e)) {
      const { errors } = e as { errors: string[] }
      const queryName = getQueryName(query)
      Sentry.captureMessage(JSON.stringify(errors, null, 2), 'debug')
      Sentry.captureEvent(
        {
          level: 'error',
          message: `Unable to run query ${queryName}`,
        },
        {
          data: {
            error: JSON.stringify(errors, null, 2),
            query,
          },
        }
      )
      console.error(e.errors)
      throw new Error(`Unable to run query ${queryName}`)
    }
    return e as GraphQLResult<QueryType>
  }
}
export default graphQLRequest
