import { captureEvent } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { list } from 'aws-amplify/storage'
import { last, pipe, split } from 'ramda'

import { listDocuments } from '@/graphql/queries'
import { useAppsyncRefetch } from '@/hooks'
import { graphQLRequest } from '@/utils'
import {
  DocTypes,
  Document,
  ListDocumentsQuery,
  ListDocumentsQueryVariables,
  ProcessingStatus,
} from '@/utils/api'

export const useListDocuments = (
  documentSetId: string,
  orgID?: string | null,
  fetchAll = true
) => {
  const isRefetching = useAppsyncRefetch((state) =>
    state.refetchStatus(documentSetId)
  )

  const setRefetch = useAppsyncRefetch((state) => state.setRefetch)
  return useQuery({
    enabled: !!documentSetId && !!orgID,
    queryFn: async () => {
      const { items: s3Items } = await list({
        options: {
          accessLevel: 'guest',
          listAll: true,
        },
        prefix: `${orgID}/documents/${documentSetId}`,
      })
      let items: Document[] = []
      let finishedGettingDocuments = !fetchAll
      let nextToken: string | null | undefined
      // TODO: refactor this in the future to show paginated and searchable results
      do {
        const req = await graphQLRequest<
          ListDocumentsQuery,
          ListDocumentsQueryVariables
        >(listDocuments, {
          ...(!!nextToken && { nextToken }),
          filter: {
            active: { eq: true },
            namespaceDocumentsId: { eq: documentSetId },
          },
        })
        items = [
          ...items,
          ...((req.data?.listDocuments?.items ?? []) as Document[]),
        ]
        nextToken = req.data.listDocuments?.nextToken
        if (!req.data.listDocuments?.nextToken) finishedGettingDocuments = true
      } while (!finishedGettingDocuments)

      setRefetch(
        documentSetId,
        !!items.find(
          (i) =>
            i?.status === ProcessingStatus.processing ||
            i?.status === ProcessingStatus.init
        )
      )
      const getKey = pipe(split('/'), last)

      return (items
        .filter((item) => {
          if (!item) return false
          if (item.type !== DocTypes.pdf) return item
          return s3Items.some((s3Item) => {
            //@ts-ignore
            return s3Item.key.includes(getKey(item?.source ?? ''))
          })
        })
        .map((doc) => {
          if (!doc) return null
          const timeDifference = Math.abs(
            new Date(doc.updatedAt).getTime() - new Date().getTime()
          )

          const updatedLaterThan15Min = timeDifference > 900000
          const updatedLaterThan2Min = timeDifference > 120000

          if (updatedLaterThan2Min && doc.status === ProcessingStatus.init) {
            captureEvent({
              level: 'error',
              message: "The upsert document lamda didn't start correctly",
              tags: {
                type: 'Lamda',
              },
            })
            doc.status = ProcessingStatus.error
            doc.displayStatus =
              'The document did not upsert correctly, please retry'
          }
          if (
            updatedLaterThan15Min &&
            doc.status === ProcessingStatus.processing
          ) {
            captureEvent({
              level: 'error',
              message: 'The upsert document lamda timed out',
              tags: {
                type: 'Lamda',
              },
            })
            doc.status = ProcessingStatus.error
            doc.displayStatus = 'The lamda timed out, please retry'
          }
          return {
            ...doc,
            displayStatus: updatedLaterThan2Min
              ? "The document didn't upsert correctly"
              : doc.displayStatus,
          }
        }) ?? []) as Document[]
    },
    queryKey: ['documentSet', documentSetId, 'documents'],
    refetchInterval: isRefetching ? 1000 : false,
  })
}
