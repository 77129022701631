import { assocPath, curry, dissocPath, path, pipe } from 'ramda'

const modifyPath = curry(
  (from: string[], to: (string | number)[], obj: Record<string, any>) => {
    const v = path(from, obj)
    return pipe(dissocPath(from), assocPath(to, v))(obj)
  }
)

export default modifyPath
