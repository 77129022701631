import { path } from 'ramda'
import { useEffect } from 'react'
import { UseFormReturn, ValidationRule } from 'react-hook-form'

export const getError = (
  formState: Record<string, any>,
  errPath: string
): string | undefined =>
  //@ts-ignore
  path(['errors', ...errPath.split('.'), 'message'], formState)

/**
 * This function will generate a uuidV4 id string as a random number based
 * with some crypto magic
 */
export function uuidV4(): string {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

/**
 * Unregisters an item from a form using React Hook Form.
 * @param form - The form object returned by useForm.
 * @param name - The name of the item to unregister.
 */
export function useUnregisterItem<T extends string>(
  form: UseFormReturn,
  name: T
) {
  const { unregister } = form

  /**
   * Cleanup function that unregisters the item when the component is unmounted.
   */
  useEffect(() => {
    return () => {
      unregister(name)
    }
  }, [name, unregister])
}

export const maxLength = (length: number): ValidationRule<number> => ({
  message: `Max length is ${length}`,
  value: length,
})
export const minLength = (length: number): ValidationRule<number> => ({
  message: `Min length is ${length}`,
  value: length,
})
export const required = 'This field is required'
