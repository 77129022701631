import isNullish from '@/utils/isNullish'

function removeNullishValues<T = {}>(obj: T) {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj))
    throw new Error('The argument must be an object')

  return Object.entries(obj)
    .filter(([, val]) => !isNullish(val))
    .reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value,
      }),
      {}
    )
}
export default removeNullishValues

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest

  describe('removeNullishValues', () => {
    it('should remove all nullish values like', function () {
      const obj = { a: 1, b: 2, c: null, d: undefined, e: '', f: 0 }
      expect(removeNullishValues(obj)).toEqual({ a: 1, b: 2, e: '', f: 0 })
    })
    it.each([
      ['hello'],
      [2],
      [[1, 2, 3]],
      [false],
      [null],
      [true],
      [undefined],
    ])('removeNullishValues(%i) -> Error', (val) => {
      //We are ignoring the TS error because we are testing in runtime when we are not receiving the correct input
      // @ts-ignore
      expect(() => removeNullishValues(val)).toThrowError(
        'The argument must be an object'
      )
    })
  })
}
