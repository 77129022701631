export function addSmallestNumber(arr: string[]) {
  return arr.map((k, idx) => {
    const num = parseInt(k)
    if (num) return k
    if (!arr.find((n) => n === String(idx + 1))) return String(idx + 1)
    return String(arr.length + idx)
  })
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest

  it.each([
    { expected: ['1', '2', '3'], input: ['1', '2', '3'] },
    { expected: ['1', '2', '3', '4'], input: ['1', '2', '', ''] },
    { expected: ['1', '2', '3', '4'], input: ['1', '', '3', '4'] },
    { expected: ['4', '1', '2', '3'], input: ['', '1', '2', '3'] },
    { expected: ['4', '1', '6', '3'], input: ['', '1', '', '3'] },
  ])('addSmallestNumber($input)', ({ input, expected }) => {
    expect(addSmallestNumber(input)).toStrictEqual(expected)
  })
}
