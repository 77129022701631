import { useQuery } from '@tanstack/react-query'

import { graphQLRequest } from '@/utils'
import {
  ListPromptSetsQuery,
  ListPromptSetsQueryVariables,
  PromptSet,
} from '@/utils/api'

const listPromptSets = /* GraphQL */ `
  query ListPromptSets(
    $filter: ModelPromptSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromptSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        editors
        readers
        admins
        maxTokens
        name
        prompt
        temperature
        type
        id
        createdAt
        updatedAt
        organizationPromptSetsId
        promptSetUpdatedById
        chat {
          role
          response
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const useListPromptSet = (orgID?: string) => {
  return useQuery({
    enabled: !!orgID,

    queryFn: async () => {
      const res = await graphQLRequest<
        ListPromptSetsQuery,
        ListPromptSetsQueryVariables
      >(listPromptSets, {
        filter: {
          organizationPromptSetsId: {
            eq: orgID,
          },
        },
      })

      return (res.data?.listPromptSets?.items ?? []) as PromptSet[]
    },

    queryKey: ['promptSet', orgID],
  })
}
