import { useMutation } from '@tanstack/react-query'

import { useMyUser } from '@/hooks'
import useSnackbar from '@/hooks/useSnackbar'
import { getDefaultMutations, graphQLRequest, PartialBy } from '@/utils'
import {
  UpdateCustomComponentInput,
  UpdateCustomComponentMutation,
  UpdateCustomComponentMutationVariables,
} from '@/utils/api'

import { CustomComponentEnhanced, storeKey } from './CONSTANTS'

export const useUpdateCustomComponent = <T extends {}>(id?: string) => {
  const me = useMyUser()
  const snackbars = useSnackbar({
    errorMessage: 'There was an error updating the custom component',
    infoMessage: 'Updating the custom component...',
    successMessage: 'The custom component has been updated!',
  })
  return useMutation({
    mutationFn: async (data: PartialBy<CustomComponentEnhanced<T>, 'id'>) => {
      if (!id && !data.id) throw new Error('id is required')
      const res = await graphQLRequest<
        UpdateCustomComponentMutation,
        UpdateCustomComponentMutationVariables
      >(
        /* GraphQL */ `
          mutation UpdateCustomComponent(
            $input: UpdateCustomComponentInput!
            $condition: ModelCustomComponentConditionInput
          ) {
            updateCustomComponent(input: $input, condition: $condition) {
              editors
              readers
              admins
              name
              description
              type
              settings
              status
              displayStatus
              visibility
              id
              createdAt
              updatedAt
              organizationCustomComponentsId
              customComponentUpdatedById
              __typename
            }
          }
        `,
        {
          input: {
            ...(data as UpdateCustomComponentInput),
            customComponentUpdatedById: me.data?.id ?? '',
            id: (id || data.id) as string,
            ...(data.settings
              ? { settings: JSON.stringify(data.settings) ?? '' }
              : {}),
          },
        }
      )
      return res.data?.updateCustomComponent
    },
    mutationKey: ['updateCustomComponent'],
    ...getDefaultMutations({ queryKey: [storeKey] }, snackbars),
  })
}
