import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { DrawerItem } from '@/components/BaseNavigation/DrawerItem'

type LLMSubsectionProps = {
  open: boolean
  tooltipOpen: string | null
  onClick: (path: string) => () => void
  onClose: () => void
  onOpen: (title: string) => () => void
}

export const LLMSubsection = (props: LLMSubsectionProps) => {
  const { orgID } = useParams()

  if (!props.open) return null

  return (
    <Stack>
      <DrawerItem
        {...props}
        enabled
        path={`/${orgID}/llm/?tab=documents+sets`}
        sx={{ pl: 4.5, px: 0 }}
        title="Document Sets"
        onOpen={props.onOpen('llm')}
      />
      <DrawerItem
        {...props}
        enabled
        path={`/${orgID}/llm/?tab=prompt+sets`}
        sx={{ pl: 4.5, px: 0 }}
        title="Prompt Sets"
        onOpen={props.onOpen('llm')}
      />
    </Stack>
  )
}
