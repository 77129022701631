import { Breadcrumbs as MaterialBreadcrumbs, Typography } from '@mui/material'
import { init, last, length } from 'ramda'
import { memo } from 'react'

import { Link } from '@/components'

export interface BreadcrumbsItem {
  link: string
  name: string
}

export interface BreadcrumbsProps {
  items?: BreadcrumbsItem[]
}

/**
 * The Breadcrumbs component will render a list of links with a separator between them.
 * The last item in the list will be a Typography component.
 * @param {BreadcrumbsItem} items - The list of items to render.
 * @param {string} items[].link - The link to navigate to.
 * @param {string} items[].name - The name of the link.
 * @returns {React.ReactElement} The rendered component.
 */
export const Breadcrumbs = memo(function BreadCrumbs({
  items,
}: BreadcrumbsProps) {
  if (!items) return null
  const lastItem = last(items)
  return (
    <MaterialBreadcrumbs separator=">">
      {init(items ?? []).map((item: BreadcrumbsItem, index: number) => (
        <Link key={item.link} color="inherit" to={item.link} underline="hover">
          {item.name}
        </Link>
      ))}
      <Typography key={length(items)} color="text.primary">
        {lastItem?.name}
      </Typography>
    </MaterialBreadcrumbs>
  )
})
