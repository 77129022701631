import { ComponentType, ReactElement, Suspense } from 'react'

const DefaultLoadingComponent = () => <></>

const withSuspense = <T extends {}>(
  WrappedComponent: ComponentType<T>,
  LoadingModule?: ReactElement
) => {
  function LazyComponent(props: T) {
    return (
      <Suspense fallback={LoadingModule ?? <DefaultLoadingComponent />}>
        <WrappedComponent {...props} />
      </Suspense>
    )
  }
  return LazyComponent
}

export default withSuspense
