import { Button } from '@mui/material'
import { parse } from 'csv-parse/browser/esm'
import { concat, pick, prop, sortBy, uniqBy } from 'ramda'
import { ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetCustomComponent,
  useUpdateCustomComponent,
} from '@/screens/ComponentLibrary/hooks'
import { parseCSV } from '@/utils'

import { SemanticSearchSettings } from './NewSemanticQuestion'

const parseFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (!e.target?.result) return
      parse(e.target.result as string, {}, (err, output: string[][]) => {
        if (err) return reject(err)
        return resolve(parseCSV(output))
      })
    }
    reader.readAsText(file)
  })

const sortByKey = sortBy(prop('key'))

function getMergedQuestions<T extends Array<Record<string, unknown>>>(
  questions: T,
  newQuestions: T
): T {
  //@ts-ignore
  const uniqQuestions: T = uniqBy(prop('key'), concat(newQuestions, questions))
  return sortByKey(uniqQuestions) as T
}

export function SemanticSearchUploader() {
  const { componentID } = useParams()
  const updateSemanticSearch =
    useUpdateCustomComponent<SemanticSearchSettings>(componentID)
  const semanticSearch =
    useGetCustomComponent<SemanticSearchSettings>(componentID)

  if (!componentID) return null

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    if (!file) return

    const rawQuestions = (await parseFile(file)) as Record<string, unknown>[]

    const safeQuestions = rawQuestions.map((question, idx) => {
      const obj = pick(
        ['question', 'answerText', 'key', 'source', 'suggestedQuestions'],
        {
          key: `${idx + 1}`,
          ...question,
        } as SemanticSearchSettings['questions'][0]
      )
      //@ts-ignore
      Object.keys(obj).forEach((k) => (obj[k] = obj[k]?.trim?.()))
      return obj
    }) as SemanticSearchSettings['questions']

    // @ts-ignore
    const mergedQuestions = getMergedQuestions(
      semanticSearch.data?.settings?.questions || [],
      safeQuestions
    )
    await updateSemanticSearch.mutateAsync({
      id: componentID,
      settings: {
        ...semanticSearch.data?.settings,
        questions: uniqBy(prop('key'), mergedQuestions).map((q, _, arr) => {
          if (!q.suggestedQuestions) return { ...q, suggestedQuestions: [] }
          let suggested = q.suggestedQuestions
          if (typeof q.suggestedQuestions === 'string')
            //@ts-ignore
            suggested = q.suggestedQuestions.split(',').map((s) => s.trim())

          return {
            ...q,
            suggestedQuestions:
              suggested?.filter((sq) => arr.find((aq) => aq.key === sq)) || [],
          }
        }),
      },
    })
  }
  return (
    <Button component="label">
      Upload from CSV
      <input
        hidden
        multiple
        accept="text/csv"
        type="file"
        onChange={onChange}
      />
    </Button>
  )
}
