import { Box, CircularProgress } from '@mui/material'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'

export const GlobalFetchingIndicator = () => {
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  if (!isFetching && !isMutating) return null

  return (
    <Box
      alignItems="center"
      bottom={10}
      display="flex"
      height={100}
      justifyContent="center"
      left="45%"
      position="fixed"
      right="55%"
      width={100}
    >
      <CircularProgress />
    </Box>
  )
}
