import { useQuery } from '@tanstack/react-query'

import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  Organization,
} from '@/utils/api'
import graphQLRequest from '@/utils/graphqlClient'

const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      history {
        nextToken
        __typename
      }
      name
      orgStatus
      languagesSupported
      privacyPolicy
      slug
      tags
      termsOfUse
      updatedBy {
        email
        name
        role
        demosShown
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      id
      createdAt
      updatedAt
      organizationUpdatedById
      phoneNumber
      solutionDeployed
      __typename
    }
  }
`

export const useOrg = (orgID?: string) => {
  return useQuery({
    enabled: !!orgID,

    queryFn: async () => {
      const org = await graphQLRequest<
        GetOrganizationQuery,
        GetOrganizationQueryVariables
      >(getOrganization, { id: orgID as string })
      if (org.data?.getOrganization === null) throw new Error('Org not found')
      if (org.data?.getOrganization?.orgStatus === 'active')
        return org.data?.getOrganization as Organization
      throw new Error('Org is not active')
    },

    queryKey: ['organizations', orgID],
  })
}
