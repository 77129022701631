import { useMutation } from '@tanstack/react-query'

import { createNamespace } from '@/graphql/mutations'
import { useMySnackbar, useMyUser } from '@/hooks'
import { getDefaultMutations, graphQLRequest } from '@/utils'
import {
  CreateNamespaceInput,
  CreateNamespaceMutation,
  CreateNamespaceMutationVariables,
  Namespace,
} from '@/utils/api'

export function useCreateDocumentSet(orgID?: string) {
  const me = useMyUser()
  const snackbars = useMySnackbar({
    errorMessage: 'Error creating document set',
    infoMessage: 'Creating document set...',
    successMessage: 'Document set created successfully',
  })
  return useMutation({
    mutationFn: async (form: CreateNamespaceInput) => {
      const safeOrg = form.organizationNamespacesId ?? orgID
      if (!safeOrg) throw new Error('No org ID provided')
      const res = await graphQLRequest<
        CreateNamespaceMutation,
        CreateNamespaceMutationVariables
      >(createNamespace, {
        input: {
          ...form,
          namespaceUpdatedById: me.data?.id as string,
          organizationNamespacesId: safeOrg,
        },
      })
      return res.data?.createNamespace as Namespace
    },
    ...getDefaultMutations({ queryKey: ['documentSet', orgID] }, snackbars),
  })
}
