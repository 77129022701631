import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { EditTabsPattern, PageHeader } from '@/components'
import { useOrg } from '@/hooks'

import { DocumentSet, PromptSet } from './tabs'

export function EditLLM() {
  const { orgID } = useParams()

  const org = useOrg(orgID)
  return (
    <Stack gap={2} sx={{ height: '100%' }}>
      <PageHeader
        breadcrumbs={[
          { link: '../../..', name: 'Home' },
          {
            link: '../..',
            name: org.data?.name ?? 'Loading org name...',
          },
        ]}
        title="LLM@LLS"
      />
      <EditTabsPattern
        showTabs
        defaultTab="documents sets"
        tabs={[
          { component: <DocumentSet />, name: 'documents sets' },
          { component: <PromptSet />, name: 'prompt sets' },
        ]}
      />
    </Stack>
  )
}
