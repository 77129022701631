/**
 * Convert a string to camelCase
 * @param {string}str - The string to convert
 */
export function camelize(str?: string) {
  if (!str) return ''
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/[^a-zA-Z]+/g, '')
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest

  it.each([
    { expected: 'thisIsGreat', input: 'this is great' },
    { expected: 'thisIsAwesome', input: 'This is awesome' },
  ])('camelize($input) -> $expected', ({ expected, input }) => {
    expect(camelize(input)).toBe(expected)
  })
}
