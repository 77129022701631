import {
  createTheme,
  PaletteMode,
  ThemeOptions,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'
import { ReactNode, useEffect, useMemo } from 'react'

import { useLocalStorage } from '@/hooks/useLocalStorage'
import useMyUser from '@/hooks/useMyUser'

const darkPaletteText = {
  disabled: 'rgb(156, 163, 175)',
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(148, 163, 184)',
}
const lightPaletteText = {
  disabled: 'rgb(149, 156, 169)',
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
}

const getDesignTokens = (mode: PaletteMode) => {
  const common: ThemeOptions = {
    palette: {
      divider: 'rgba(0,0,0,0.12)',
      error: {
        main: '#ff5454',
      },
      primary: {
        main: '#3a9999',
      },
      secondary: {
        main: '#ffdd66',
      },
    },
  }
  const darkPalette: ThemeOptions = {
    palette: {
      ...common.palette,
      background: {
        default: '#484848',
        paper: '#121212',
      },
      mode: 'dark',
      text: darkPaletteText,
    },
  }
  const lightPalette: ThemeOptions = {
    palette: {
      ...common.palette,
      mode: 'light',
      text: lightPaletteText,
    },
  }

  return mode === 'dark' ? darkPalette : lightPalette
}
const setCSSVariables = (mode: PaletteMode) => {
  const common = {
    p: '0 0% 89%',
    pc: '0 0% 0%',
    s: '200 100% 40%',
    sc: '0 0 100%',
  }
  const darkMode = {
    ...common,
  }
  const lightMode = {
    ...common,
  }
  const root = document.documentElement
  Object.entries(mode === 'dark' ? darkMode : lightMode).forEach(
    ([key, value]) => {
      root.style.setProperty(`--${key}`, value)
    }
  )
}

export function CustomTheme({ children }: { children: ReactNode }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light'
  const [initialTheme, setTheme] = useLocalStorage<PaletteMode>(
    'theme',
    prefersDarkMode
  )
  const me = useMyUser()
  useEffect(() => {
    if (me.data?.settings?.theme) {
      setTheme(me.data.settings.theme)
    }
  }, [me.data?.settings?.theme, setTheme])

  // Update the theme only if the mode changes
  const theme = useMemo(() => {
    const { theme, fontSize } = me.data?.settings ?? {}
    const fontFamily = ["'Plus Jakarta Sans'", 'sans-serif'].join(',')
    setCSSVariables(theme ?? initialTheme)
    return createTheme({
      ...getDesignTokens(theme ?? initialTheme),
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                cursor: 'not-allowed',
              },
            },
          },
        },
      },
      typography: {
        fontSize: fontSize ?? 14,
        h4: {
          fontFamily,
          fontWeight: 800,
        },
        h5: {
          fontFamily,
          fontWeight: 600,
        },
        h6: {
          color:
            (theme ?? initialTheme) === 'dark'
              ? darkPaletteText.primary
              : 'rgba(44,56,74,0.8)',
          fontFamily,
          fontWeight: 600,
        },
      },
    })
  }, [me.data?.settings, initialTheme])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
